import { RemoteSeries } from '../Strategy/seriesStrategy/remoteSeries';

import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { Observable, of, from } from 'rxjs';

import { Series } from './../models/series';
import { Serie } from '../models/serie';
import { Student } from '../models/student';

import { ServiceLocator } from './serviceLocator.service';
import { Initialable } from './app-init.service';
import { StorageService } from './storage.service';
import { StudentService } from './student.service';
import { QuestionsService } from './questions.service';
import { SeriesContext } from '../Strategy/seriesStrategy/seriesContext';
import { LocalSeries } from '../Strategy/seriesStrategy/localSeries';
import { Platform } from '@ionic/angular';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { UserErrorHandlerService } from './user-error-handler.service';
import { NetworkStatusService } from './network-status.service';

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init5', initializer: 'onInit'})
@EasyDebugDecorator
export class SeriesService extends Series {
  private _seriesId: Array<Serie> = [];
  private _seriesSize = 0;
  private _seriesContext: SeriesContext;
  public currentSerie: Serie = null;
  public errorSerie: Serie = null;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private studentService: StudentService,
    private questionsService: QuestionsService,
    private platform: Platform,
    private userErrorHandlerService: UserErrorHandlerService,
    private networkStatusService: NetworkStatusService
  ) {
    super();
  }

  async onInit() {
    this.currentSerie = null;
    this.errorSerie = null;
    const transformed = await this.storageService.get('transformed');
    // const transformed = true;

    if (this.platform.is('cordova') || transformed) {
      this._seriesContext = new SeriesContext(new LocalSeries(this.storageService, ServiceLocator.injector.get(HttpClient), this.networkStatusService));
    } else {
      this._seriesContext = new SeriesContext(new RemoteSeries(ServiceLocator.injector.get(HttpClient)));
      this._seriesContext.init();
    }
    return 'Series done';
  }

  async fillSeriesId(series: Array<any>, student: Student): Promise<any> {
    let scopedSerie = [];
    const newSeries = [];
    if (!!student && !!series && series.length > 0) {

      if (!this.studentService.student) {
        scopedSerie = series.filter(elt => elt.attributes.scope === 'free');
      } else {
        scopedSerie = series;
      }
      this._seriesSize = (!!scopedSerie) ? scopedSerie.length : 0;
      this._seriesId = await this._seriesContext.getSeriesList(student, true) || [];

      // console.log('fill series id => ', JSON.parse(JSON.stringify(series)));

      if (!!series && series.length > 0) {
        for (const serie of series) {
          const lastSessions: string[] = [];
          const serieId = this._seriesId.find(elt => elt.id === serie.id);
          const downloadState = (!!serieId && !!serieId.downloadState) ? serieId.downloadState : 'none';

          lastSessions.push((!!serie.attributes.last_session) ? ((!!serie.attributes.last_session.training.data) ? serie.attributes.last_session.training.data.id : null) : null);
          lastSessions.push((!!serie.attributes.last_session) ? ((!!serie.attributes.last_session.exam.data) ? serie.attributes.last_session.exam.data.id : null) : null);

          const newSerie = new Serie({
            id: (!!serie.id) ? serie.id : null,
            position: ((!!serie.attributes && !!serie.attributes.position) || (!!serie.attributes && serie.attributes.position === 0)) ? serie.attributes.position : null,
            type: (!!serie.attributes && !!serie.attributes.type) ? serie.attributes.type : null,
            scope: (!!serie.attributes && !!serie.attributes.scope) ? serie.attributes.scope : null,
            downloadState: downloadState,
            updatedAt: (!!serie.attributes && !!serie.attributes.updated_at) ? serie.attributes.updated_at : null,
            lastSessions: lastSessions,
          });
          newSeries.push(newSerie);
          if (!!this._seriesId && !!this._seriesId.find(elt => elt.id === serie.id)) {
            this._seriesId.splice(this._seriesId.findIndex(elt => elt.id === serie.id), 1, newSerie);
          } else {
            this._seriesId.push(newSerie);
          }
        }
      }
      // console.log('feel series id => ', JSON.parse(JSON.stringify(this._seriesId)));
      await this._seriesContext.setSeriesList(this._seriesId, student);
    }
    return new Promise(resolve => resolve(newSeries));
  }

  incorporateQuestions(serie: any): Observable<Serie> {
    if (!!serie && !!serie.id && !serie.erroCode) {
      return this.questionsService.fetchQuestionsBySerie(serie.id).pipe(
        switchMap(
          questions => {
            if (!!questions && questions.length > 0) {
              const questionsId = [];
              for (const question of questions) {
                if (!!question.id) {
                  questionsId.push(question.id);
                }
              }
              serie.questions = questionsId;
            }
            return of(serie);
          }
        )
      );
    } else {
      return of(null);
    }
  }

  handleSeriesCalls(seriesId: Serie[], student: Student): Observable<Serie> {
    if (!!student && !!seriesId && seriesId.length > 0) {
      return from(seriesId).pipe(
        mergeMap(
          serieId => {
            // À CHANGER
            if (
              !!serieId.scope &&
              (
                serieId.scope === '3free' || serieId.scope === 'free' ||
                (!!student && !!student.status && student.status !== 'guest')
              )
            ) {
              return this.fetchSerie(serieId.id, student).pipe(
                switchMap(
                  (serie: Serie) => {
                    return this.incorporateQuestions(serie);
                  }
                )
              );
            }
            return of(null);
          }
        )
      );
    }
    return null;
  }

  fetchSerie(serieId: string, student: Student): Observable<any> {
    let url: string;
    if (!!serieId) {
      if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
        url = `${environment.cdrBase}/v${environment.apiOptiVersion}/account/${student.remoteId}/series/${serieId}`;
      } else {
        url = `${environment.cdrBase}/v${environment.apiOptiVersion}/series/${serieId}`;
      }
      return this.http.get(url).pipe(
        switchMap(
          (dataSerie: any) => {
            return of(this.fillSerie(dataSerie.data));
          }
        ),
        catchError(
          err => {
            // this.userErrorHandlerService.addError({criticity: 20, service: 'fetchSerie', platform: 'both', data: err, errorCode: 'ssfsr'});
            return of({ errorCode: 'E301', errorMessage: 'fetchSerie Service failed', errorOriginal: err});
          }
        )
      );
    }
    return null;
  }


  toSyncAll(series: Serie[], student: Student): Serie[] {
    const seriesToSync: Serie[] = [];
    if (!!student && !!series && series.length > 0) {
      for (const serie of series) {
        if (
          (!!serie.scope && (serie.scope === '3free' || serie.scope === 'free')) ||
          (!!student && !!student.status && student.status !== 'guest')
        ) {
          seriesToSync.push(new Serie({
            id: (!!serie.id) ? serie.id : null,
            position: (!!serie.position) ? serie.position : null,
            type: (!!serie.type) ? serie.type : null,
            scope: (!!serie.scope) ? serie.scope : null,
            downloadState: (!!serie.downloadState) ? serie.downloadState : 'none',
            updatedAt: (!!serie.updatedAt) ? serie.updatedAt : null,
            lastSessions: (!!serie.lastSessions) ? serie.lastSessions : null,
          }));
        }
      }
    }
    return seriesToSync;
  }

  pickSerieToSync(series: Serie[], localSeries: Serie[], student: Student): Serie[] {
    const seriesToSync: Serie[] = [];
    // console.log('pick serie to sync => ', JSON.parse(JSON.stringify(series)));
    if (!!student && !!series && series.length > 0 && !!localSeries && localSeries.length > 0) {
      for (const serie of series) {
        if (!!serie.id) {
          const localSerie = this.seriesContext.getSerie(serie.id, student, false, true);
          if (!!localSerie) {
            // À CHANGER
            if (
              (!!serie.scope && (serie.scope === '3free' || serie.scope === 'free')) ||
              (!!student.status && student.status !== 'guest')
            ) {
              seriesToSync.push(new Serie({
                id: serie.id,
                position: (!!serie.position) ? serie.position : null,
                type: (!!serie.type) ? serie.type : null,
                scope: (!!serie.scope) ? serie.scope : null,
                downloadState: (!!serie.downloadState) ? serie.downloadState : 'none',
                updatedAt: (!!serie.updatedAt) ? serie.updatedAt : null,
                lastSessions: (!!serie.lastSessions) ? serie.lastSessions : null,
              }));
            }
          }
        }
      }
    }
    return seriesToSync;
  }

  seriesNeedSync(series: Serie[], localSeries: Serie[], student: Student): Serie[] {
    let seriesToSync: Serie[] = [];
    if (!!student && !!series && series.length > 0 && !!localSeries && localSeries.length > 0) {
      seriesToSync = this.pickSerieToSync(series, localSeries, student);
    }
    return seriesToSync;
  }

  handleSeriesToSync(seriesToSync: Serie[], student: Student): Promise<Serie[]> {
    return new Promise((resolve) => {
      const series: Serie[] = [];
      let count = 0;
      if (!!student && !!seriesToSync && seriesToSync.length > 0) {
        this.handleSeriesCalls(seriesToSync, student).subscribe(
          async serie => {
            // console.log('handleSeriesToSync serie returned by server with questions', JSON.parse(JSON.stringify(serie)));
            if (!!serie.id) {
              const serieToSync = seriesToSync.find(elt => elt .id === serie.id);
              serie.downloadState = (!!serieToSync && !!serieToSync.downloadState) ? serieToSync.downloadState : 'none';
              const storageSerie = await this._seriesContext.setSerie(serie, student);
              // console.log('handleSeriesToSync storageSerie', JSON.parse(JSON.stringify(storageSerie)));
              if (!!storageSerie) {
                series.push(serie);
              }
              if (seriesToSync.length === (count + 1)) {
                const localSeries = await this._seriesContext.getSeriesList(student, true);
                // console.log('handleSeriesToSync localSeries', JSON.parse(JSON.stringify(localSeries)));
                if (!!localSeries && localSeries.length > 0) {
                  for (const localSerie of localSeries) {
                    if (!!localSerie.id) {
                      const serieIndex = series.findIndex(elt => elt.id === localSerie.id);
                      // console.log('handleSeriesToSync localSeries serieIndex', serieIndex);
                      if (serieIndex !== -1) {
                        const localSerieIndex = localSeries.findIndex(elt => elt.id === localSerie.id);
                        if (localSerieIndex !== -1) {
                          localSeries[localSerieIndex] = series[serieIndex];
                        }
                      }
                    }
                  }
                  await this._seriesContext.setSeriesList(localSeries, student);
                }
                resolve(series);
              }
              count++;
            }
            resolve(series);
          }
        );
      }
      resolve(series);
    });
  }

  syncSeries(updatedAt: string, student: Student): Observable<Serie[]> {
    let localSeries: Serie[] = [];
    if (!!updatedAt && !!student) {
      return this.fetchSeries(updatedAt, student).pipe(
        switchMap(
          async series => {
            // console.log('seriesServices syncSeries fetchSeries series', series);
            localSeries = await this._seriesContext.getSeriesList(student, true);
            // console.log('seriesServices syncSeries fetchSeries localSeries', localSeries);
            if (!!series && series.length > 0 && !!localSeries && localSeries.length > 0) {
              const seriesToSync = this.seriesNeedSync(series, localSeries, student);
              // console.log('seriesServices syncSeries fetchSeries seriesToSync', localSeries);
              if (!!seriesToSync && seriesToSync.length > 0) {
                return this.handleSeriesToSync(seriesToSync, student);
              }
            }
            return localSeries;
          }
        )
      );
    }
    return of(localSeries);
  }

  fetchSeries(updatedAt: string, student: Student): Observable<Serie[]> {
    this._seriesId = [];
    let url: string;
    let query = '';

    if (!!updatedAt) {
      query = `?updated_at=${updatedAt}`;
      query = query.replace('+', '%2B');
      if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
        url = `${environment.cdrBase}/v${environment.apiVersion}/account/${student.remoteId}/series${query}`;
      } else {
        url = `${environment.cdrBase}/v${environment.apiVersion}/series${query}`;
      }

      return this.http.get(url).pipe(
        switchMap(
          async (seriesData: any) => {
            if (!!seriesData && !!seriesData.data) {
              const series = await this.fillSeriesId(seriesData.data, student);
              return series;
            } else {
              return null;
            }
          }
        ),
        catchError(
          async err => {
            this.userErrorHandlerService.addError({criticity: 20, service: 'fetchSeries', platform: 'both', data: err, errorCode: 'ssfss'});
            return null;
          }
        )
      );
    }
    return of(null);
  }

  async syncThemes(student: Student): Promise<Array<string>> {
    if (!!student) {
      const questions = await this.questionsService.questionsContext.getQuestions(student, true);
      let themes = [];
      const resGetThemes = await this._seriesContext.getThemes(student);
      if (!!resGetThemes && !!resGetThemes.errorCode && resGetThemes.errorCode === 'E301') {
        // service failed
      } else {
        themes = resGetThemes;
      }
      if (!!themes && themes.length > 0) {
        return themes;
      }
      if (!!questions && questions.length > 0) {
        const themesName = Array.from(new Set(questions.map((item: any) => item.themes.join()))).filter(elt => !!elt);
        if (!!themesName && themesName.length > 0) {
          for (const themeName of themesName) {
            const themeQuestions = questions.filter(elt => elt.themes.includes(themeName));
            themes.push({
              name: themeName,
              questions: themeQuestions.map(elt => elt.id) || [],
            });
          }
        }
        return await this._seriesContext.setThemes(themes, student);
      }
    }
    return [];
  }

  resetStats(): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v3/sessions/reset`;

    return this.http.post(url, null).pipe(
      switchMap(data => {
        return of(data);
      }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'resetStats Service failed', errorOriginal: err};
        }
      )
    );
  }

  async generateThematicSerie(theme: string, student: Student): Promise<string[]> {
    const questions: string[] = [];
    if (!!student && !!theme) {
      // console.log('generateThematicSerie', theme, JSON.stringify(student));
      // console.log('seriesContext.getThemes');
      // toutes les themes
      let themes = [];
      const resGetThemes = await this._seriesContext.getThemes(student);
      if (!!resGetThemes && !!resGetThemes.errorCode && resGetThemes.errorCode === 'E301') {
        // service failed
      } else {
        themes = resGetThemes;
      }
      // console.log('Themes from seriecontext', JSON.stringify(themes));
      // toutes les questions du theme
      if (themes.length > 0) {
        let themePool = themes.find(elt => elt.name === theme).questions;
        // console.log('themePool', JSON.stringify(themePool));
        // liste de toutes les questions d'un theme
        let lQuestions = [];
        const resGetThemes2 = await this._seriesContext.getThemes(student, false);
        if (!!resGetThemes2 && !!resGetThemes2.errorCode && resGetThemes2.errorCode === 'E301') {
          // service failed
        } else {
          if (resGetThemes2.length > 0) {
            lQuestions = resGetThemes2.find(elt => elt.name === theme).questions;
          }
        }
        // console.log('WHILE', JSON.stringify(lQuestions));

        // le prob c'est que le while boucle de manière infinie
        // surement a cause du fait que les questions restantes sont mal reset par le truc des setThemes
        let cpt = 0;
        let whileHasMadeInfiniteLoop = false;
        while (questions.length < 40) {
          // prend aleatoirement des questions du theme pour former la serie thematique
          let index = Math.floor(Math.random() * themePool.length);
          // si t'as plus qu'une seule question dans la pool alors prend la
          if (themePool.length === 1 && index > 0) {
            index = 0;
          }
          // si la question random n'est pas déjà dans la liste de questions alors je push
          if (!questions.includes(themePool[index])) {
            questions.push(themePool[index]); // push dans question
            themePool.splice(index, 1); // remove de pool
            // console.log('push dans questions', questions);
          }
          // si plus de question dans la pool
          if (themePool.length === 0) {
            // console.log('reset pool');
            themePool = lQuestions;
          }
          cpt++;
          if (cpt > 600) { // arbitrary value based on 1600 / 11 themes = 150 * 4 to be secure
            whileHasMadeInfiniteLoop = true;
            break;
          }
        }
        // console.log('CPT', cpt);
        if (whileHasMadeInfiniteLoop) {
          return [];
        } else {
          // ici on enlève des questions
          themes.find(elt => elt.name === theme).questions = themePool;
          themes = await this._seriesContext.setThemes(themes, student);
          // console.log('generateThematicSerie', JSON.stringify(questions));
          return questions;
        }
      }
    }
    return new Promise(resolve => resolve(questions));
  }

  createSerie(type: string, questions: string[], student: Student): Observable<any> {
    if (!!student && !!type && !!questions && questions.length > 0) {
      // console.log('createSerie', type, questions, student);
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${student.remoteId}/serie`;
      const data = {
        serie_type: `Cdr::${type}`,
        questions: questions,
      };

      return this.http.post(url, data).pipe(
        switchMap(async (serie: any) => {
          let newSerie = this.fillSerie(serie.data);
          if (!!newSerie) {
            newSerie.questions = questions;
            newSerie = await this._seriesContext.setSerie(newSerie, student);

            let series = await this._seriesContext.getSeriesList(student);
            if (!!series.errorCode && series.errorCode === 'E301') {
              series = [];
            }
            if (!!newSerie) {
              series.push(new Serie({
                id: (!!newSerie.id) ? newSerie.id : null,
                position: (!!newSerie.position) ? newSerie.position : null,
                type: (!!newSerie.type) ? newSerie.type : null,
                scope: (!!newSerie.scope) ? newSerie.scope : null,
                theme: (!!newSerie.theme) ? newSerie.theme : null,
                downloadState: (!!newSerie.downloadState) ? newSerie.downloadState : 'none',
                updatedAt: (!!newSerie.updatedAt) ? newSerie.updatedAt : null,
                lastSessions: (!!newSerie.lastSessions) ? newSerie.lastSessions : null,
                nbQuestions: (!!newSerie.nbQuestions) ? newSerie.nbQuestions : null,
              }));
              await this._seriesContext.setSeriesList(series, student);
            }
          }
          return newSerie;
        }),
        catchError(
          async err => {
            return null;
          }
        )
      );
    }
  }

  resetErrors(): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/error_serie`;
    return this.http.delete(url).pipe(
      switchMap(async (res: any) => {
        return res;
      }),
      catchError(
        async err => {
          return null;
        }
      )
    );
  }

  public get seriesSize() { return this._seriesSize; }
  public set seriesSize(value) { this._seriesSize = value; }
  public get seriesContext(): SeriesContext { return this._seriesContext; }
}
