import { SiteOpener } from './services/site-opener.service';
import { KameleoonService, SentryService } from '@app/app.services';
import { ModalIframeComponent, ModalMarketingComponent } from '@app/modals';
import { Subscription } from 'rxjs';
import { environment } from './../environments/environment';
import { AppRatingService } from './services/app-ratings.services';
import { FeatureFlagsService } from './services/feature-flags.service';

// Angular
import { ChangeDetectorRef, Component, OnInit, HostListener, NgZone, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// Ionic
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { ModalController, Platform } from '@ionic/angular';

// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import {
  AnswersService,
  AppInitService,
  AssetsService,
  BrowserUpdateService,
  CDRLessonsService,
  CPFSubscriptionsService,
  CerfaService,
  ConfigService,
  ConfigUserService,
  CouponsService,
  DevDebugModeService,
  EasyDebugService,
  GamificationService,
  GiftsService,
  LessonsService,
  LoggerService,
  MigrationService,
  NavigationService,
  NetworkStatusService,
  OffersService,
  PageLoaderService,
  PaymentsService,
  PermissionsService,
  PointsDeRendezVousService,
  QuestionsService,
  SeriesService,
  SessionsService,
  StatsService,
  StorageService,
  StudentDocumentsService,
  StudentService,
  SyncCDRService,
  UserSessionService,
  TunnelPaiementService,
  MapTeacherService,
  ReserverLeconService,
} from './app.services';

import { ToasterService, UtilsService } from '@commons/services';
import { SoftDeployService } from './services/softDeploy.service';

import { ToasterCustomService } from './../commons/services/toaster-custom.service';

// EASY DEBUG
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { EasyDebugDecorator } from './decorators/easy-debug.decorator';
import { EasyDebugLogger } from './easy-debug-logger/easy-debug-logger';
import { ErrorCatcherService } from './services/errorCatcher.service';

import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetService } from '@nitsanzo/ngx-zendesk-webwidget';
import { WonderPushService } from './services/wonderpush.service';

import * as moment from 'moment-timezone';

class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = true;
  timeOut = 30000;
  accountUrl = environment.env === 'production' ? 'envoituresimone.zendesk.com' : 'envoituresimone1690361742.zendesk.com';
  callback(zE) {
    zE('webWidget', 'setLocale', 'fr');
    zE('webWidget', 'hide');
  }
}

@EasyDebugDecorator
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('pageLoader', { static: true }) pageLoader: any;

  isCordova = false;
  barWidth = 0;

  student: any;
  configUser: any;
  configApp: any;

  subscription = null;
  fakeFirstStep = false;

  subSync: Subscription = null;
  simpleToasterClickedSubscription: Subscription = null;

  platformReady = false;

  hideFooterNav = false;
  inputs = ['input', 'select', 'textarea'];

  versionMessagesSub = null;

  recordingMode: any;
  displayModalSendReportDebugMode = false;
  displayModalSendSilentReportDebugMode = false;
  report: any = {};

  easyDebugLogger = EasyDebugLogger.getInstance();

  imageAddr = 'https://envoituresimone.imgix.net/6bu803f3ptiwqsvbn5giysktb2re';
  downloadSize = 367000; // bytes

  currentRoute: any;
  splashCalled = false;
  startTimeOnSplash: any;
  timeInterval: any;
  splashScreenWentWell = false;

  appInitdone = false;
  silentReportSent = false;

  lastClick = null;

  needLogIn: string[] = [
    'profil',
    'documents',
    'achats',
    'edit-profil',
    'edit-email',
    'edit-password',
    'lecons',
    'dashboard',
    'series/erreurs',
    'series/examens',
    'series/thematiques',
    'notation'
  ];

  stopWootricLoop = false;
  maxLoopWootric = 100;
  cptLoopWootric = 0;

  tunnelTestAB = null;

  callCenterPhone = environment.callCenterPhone;
  displayCallCenter = false;
  callCenterClientType = ['registered', 'code_premium', 'code', 'credit_examen_cdr'];

  // force push

  @HostListener('document:backbutton', ['$event']) goBack(event) {
    this.goBackHistory();
  }

  @HostListener('click', ['$event']) onClickListener(event) {
    this.easyDebugLogger.logListener('Click', event.path);
  }

  constructor(
    public platform: Platform,
    private device: Device,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private network: Network,
    private appInitService: AppInitService,
    private pageLoaderService: PageLoaderService,
    private easyDebugService: EasyDebugService,
    private devDebugModeService: DevDebugModeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private payService: PaymentsService,
    private currentUser: StudentService,
    private cerfaService: CerfaService,
    private storage: StorageService,
    private logger: LoggerService,
    private networkStatus: NetworkStatusService,
    private migration: MigrationService,
    private userSession: UserSessionService,
    private permisions: PermissionsService,
    private syncCdr: SyncCDRService,
    private browserUpdate: BrowserUpdateService,
    private studentService: StudentService,
    private sessionsService: SessionsService,
    private questionsService: QuestionsService,
    private seriesService: SeriesService,
    private configService: ConfigService,
    private configUserService: ConfigUserService,
    private assetsService: AssetsService,
    private paymentsService: PaymentsService,
    private giftsService: GiftsService,
    private couponsService: CouponsService,
    private offersService: OffersService,
    private navigationService: NavigationService,
    private navService: NavigationService,
    private answersService: AnswersService,
    private appRatingService: AppRatingService,
    private statsService: StatsService,
    // private firebase: FirebaseX,
    private deployApi: Deploy,
    private renderer: Renderer2,
    private utils: UtilsService,
    private softDeployService: SoftDeployService,
    public modalController: ModalController,
    private toasterService: ToasterService,
    private toasterCustomService: ToasterCustomService,
    private cdrLessonsService: CDRLessonsService,
    private lessonsService: LessonsService,
    private pointsDeRendezVousService: PointsDeRendezVousService,
    private mapTeacherService: MapTeacherService,
    private reserverLeconService: ReserverLeconService,
    private deeplinks: Deeplinks,
    private file: File,
    private gamificationService: GamificationService,
    private sentryService: SentryService,
    private featureFlagsService: FeatureFlagsService,
    private kameleoonService: KameleoonService,
    private studentDocumentsService: StudentDocumentsService,
    private ref: ChangeDetectorRef,
    private ngZone: NgZone,
    private errorCatcherService: ErrorCatcherService,
    private zendeskService: NgxZendeskWebwidgetService,
    private wonderPush: WonderPush,
    private wonderPushService: WonderPushService,
    private CPFSubscriptionsService: CPFSubscriptionsService,
    private siteOpenerService: SiteOpener,
    private tunnelPaiementService: TunnelPaiementService,
  ) {
    this.isCordova = this.platform.is('cordova');

    if (environment.env !== 'production') {
      if (!this.isCordova) {
        this.activatedRoute.queryParams.subscribe(params => {
          if (!!params['apiEndpoint']) {
            environment.cdrBase = params['apiEndpoint'];
            environment.token_auth_config.apiBase = params['apiEndpoint'];
            environment.token_auth_config.secondaryApiBase = params['apiEndpoint'];
            environment.token_auth_config.oAuthBase = params['apiEndpoint'];
          }
          if (!!params['baseEndpoint']) {
            environment.legacyBase = params['baseEndpoint'];
          }
        });
      }
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (!!params['origin']) {
        this.navigationService.queryStringOrigin = params['origin'];
      } else {
        this.navigationService.queryStringOrigin = 'spa';
      }
    });

    this.activatedRoute.queryParams.subscribe(async params => {
      const new_registration = params['new_registration'];
      if (!!new_registration && new_registration) {
        if (!this.student) {
          this.student = await this.studentService.getProfil();
        }
        if (!this.configUser) {
          this.configUser = await this.configUserService.getUserConfig(this.student);
        }
        this.configUser.showPostRegistration = true;
        await this.configUserService.setUserConfig(this.student, this.configUser);
      }
      const tunnelTestAB = params['tunnelTestAB'];
      if (!!tunnelTestAB && tunnelTestAB) {
        this.configUserService.tunnelTestAB = tunnelTestAB;
        this.tunnelTestAB = tunnelTestAB;
      }
    });

    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.currentRoute = this.router.url.substr(1);
        // console.log(this.currentRoute);
        // reset just in case
        this.hideFooterNav = false;
        // if (this.currentRoute === 'splash') {
        //   this.splashCalled = true;
        //   this.silentReportSent = false;
        //   this.pageLoaderService.splashScreenStartTime();
        //   this.easyDebugService.setRecordingMode('silentRecord');
        //   this.timeInterval = setInterval(() => {
        //     this.checkIfReportNeedToBeSent();
        //   }, 200);
        // } else {
        //   if (!!this.splashCalled && this.splashCalled) {
        //     // on est passé par le splash
        //     this.easyDebugService.setRecordingMode('checkIfSend');
        //   }
        //   this.splashCalled = false;
        // }
        if (this.appInitdone) {
          // this.ref.markForCheck();
          // this.ref.detectChanges();
          // console.log('ref');
          this.checkLessonsRating();
        }
      }
    });

    this.pageLoaderService.stepChanged$.subscribe(
      async stepChanged => {
        if (!!stepChanged && stepChanged.stepsState === 'update') {
          const steps = this.pageLoaderService.getSteps();
          if (!!this.pageLoader && !!this.pageLoader.nativeElement && this.currentRoute !== 'splash') {
            if (this.pageLoader.nativeElement.style.opacity === 0) {
              this.renderer.setStyle(
                this.pageLoader.nativeElement,
                'transition',
                'none'
              );
              this.renderer.setStyle(
                this.pageLoader.nativeElement,
                'width',
                0
              );
              this.barWidth = 0;
            }
            if (this.barWidth < this.utils.getPercent(steps.currentStep, steps.nbSteps)) {
              setTimeout(() => {
                this.renderer.setStyle(
                  this.pageLoader.nativeElement,
                  'transition',
                  '.2s ease-out'
                );
                this.renderer.setStyle(
                  this.pageLoader.nativeElement,
                  'opacity',
                  '1'
                );
                this.renderer.setStyle(
                  this.pageLoader.nativeElement,
                  'width',
                  this.utils.getPercent(steps.currentStep, steps.nbSteps) + '%'
                );
                this.barWidth = this.utils.getPercent(steps.currentStep, steps.nbSteps);
                // console.warn(steps.currentStep, steps.nbSteps, this.utils.getPercent(steps.currentStep, steps.nbSteps));
              }, 0);
            }
          } else {
            if (this.currentRoute !== 'splash') {
              if (this.pageLoader.nativeElement.style.opacity === 0) {
                this.renderer.setStyle(
                  this.pageLoader.nativeElement,
                  'transition',
                  'none'
                );
                this.renderer.setStyle(
                  this.pageLoader.nativeElement,
                  'width',
                  0
                );
                this.barWidth = 0;
              }
              setTimeout(() => {
                this.fakeFirstStep = true;
              }, 0);
            }
          }
        }
        if (!!stepChanged && stepChanged.stepsState === 'done') {
          const student = this.studentService.student;
          const userProperties = await this.studentService.fetchUserProperties();

          if (!!userProperties) {
            this.displayCallCenter = (this.callCenterClientType.indexOf(userProperties?.client_type) !== -1 || !userProperties?.client_type) && !userProperties?.declared_registered_in_driving_school ? true : false;
          }

          // console.log('featureFlagsService.flags[wootric]', this.featureFlagsService.flags['wootric']);
          if (!!this.featureFlagsService.flags && !!this.featureFlagsService.flags['wootric'] && this.featureFlagsService.flags['wootric']) {
            const allowedRoutesForWootric = [
              'accueil',
              'dashboard',
              'code',
              'conduite',
              'profil',
            ];
            const authWootric = (!!this.currentRoute && allowedRoutesForWootric.indexOf(this.currentRoute) !== -1) ? true : false;

            // console.warn('authWootric', authWootric);
            // console.log('wootric userProperties', userProperties);
            // console.log('wootric sessions', (userProperties.status === 'registered' && (userProperties.user_series_session_started > 3 || userProperties.user_series_session_completed > 0)));
            // console.log('wootric check', authWootric &&
            // !!userProperties && !!userProperties.status && !!userProperties.user_series_session_started &&
            // userProperties.status !== 'teacher' &&
            // userProperties.status !== 'guest' &&
            // (
            //   userProperties.status !== 'registered' ||
            //   (userProperties.status === 'registered' && (userProperties.user_series_session_started > 3 || userProperties.user_series_session_completed > 0))
            // ));

            if (
              authWootric &&
              !!userProperties && !!userProperties.status && !!userProperties.user_series_session_started &&
              userProperties.status !== 'teacher' &&
              userProperties.status !== 'guest' &&
              (
                userProperties.status !== 'registered' ||
                (userProperties.status === 'registered' && (userProperties.user_series_session_started > 3 || userProperties.user_series_session_completed > 0))
              )
            ) {
              let userEnv = null;
              if (this.platform.is('cordova') && this.platform.is('ios')) {
                userEnv = 'iOS';
              } else if (this.platform.is('cordova') && this.platform.is('android')) {
                userEnv = 'Android';
              } else  {
                userEnv = 'web_app';
              }
              // DATE TO CHANGE
              const date = this.dateToFormatFr(userProperties.confirmed_at);
              (window as any).wootricSettings.email = student.remoteId;
              (window as any).wootricSettings.external_id = student.remoteId;
              (window as any).wootricSettings.created_at = Math.round(date.valueOf() / 1000);
              (window as any).wootricSettings.properties = {};
              (window as any).wootricSettings.properties.user_status = userProperties.status;
              (window as any).wootricSettings.properties.client_type = userProperties.client_type;
              (window as any).wootricSettings.properties.environment = userEnv;
              (window as any).wootricSettings.properties.departement = userProperties.department;
              (window as any).wootricSettings.properties.user_said_in_ae = userProperties.declared_registered_in_driving_school;
              (window as any).wootricSettings.properties.user_series_started = userProperties.user_series_session_started;
              (window as any).wootricSettings.properties.user_series_completed = userProperties.user_series_session_completed;
              (window as any).wootricSettings.properties.user_signed_up_from = userProperties.signed_up_from;
              (window as any).wootricSettings.properties.hours_passed_lessons = userProperties.hours_passed_lessons;
              (window as any).wootric('run');
              // console.log('wootric launched');
            }
          }

          this.renderer.setStyle(
            this.pageLoader.nativeElement,
            'opacity',
            '0'
          );
          setTimeout(() => {
            this.renderer.setStyle(
              this.pageLoader.nativeElement,
              'transition',
              'none'
            );
            this.renderer.setStyle(
              this.pageLoader.nativeElement,
              'width',
              0
            );
            this.barWidth = 0;
          }, 500);

          setTimeout(() => {
            this.checkIfWootricCanBeShown();
          }, 3000);
        }
      }
    );
    this.easyDebugService.recordingModeChanged$.subscribe(
      async recordingModeChanged => {
        // console.log(recordingModeChanged, recordingModeChanged.recordingMode);
        if (!!recordingModeChanged && (recordingModeChanged.recordingMode === 'record' || recordingModeChanged.recordingMode === 'silentRecord')) {
          this.recordingMode = this.easyDebugService.getRecordingMode();
          this.easyDebugLogger.isEasyDebugActive = true;
          // console.log(this.recordingMode);
        }
        if (!!recordingModeChanged && recordingModeChanged.recordingMode === 'send') {
          this.recordingMode = this.easyDebugService.getRecordingMode();
          this.showModalSendReportDebugMode();
          await this.generateReport();
        }
        if (!!recordingModeChanged && recordingModeChanged.recordingMode === 'checkIfSend') {
          this.checkIfReportNeedToBeSent();
        }
        if (!!recordingModeChanged && recordingModeChanged.recordingMode === 'sended') {
          this.hideModalSendReportDebugMode();
          this.hideModalSendSilentReportDebugMode();
          this.clearEasyDebugLogs();
          if (!this.silentReportSent) {
            this.resetSync();
          }
        }
        if (!!recordingModeChanged && recordingModeChanged.recordingMode === 'failed') {
          this.easyDebugLogger.isEasyDebugActive = false;
          this.hideModalSendReportDebugMode();
          const toasterConfig = {
            text: `Une erreur est survenue au moment de l'envoi du rapport d'erreur. Réessaie plus tard`,
            bgcolor: 'var(--color-danger)',
            duration: '5000'
          };
          this.launchToaster(toasterConfig);
        }
      }
    );
    this.simpleToasterClickedSubscription = this.toasterCustomService.simpleToasterClicked$.subscribe(
      async link => {
        if (!!link) {
          this.softDeployService.reloadApp();
          if (!!this.simpleToasterClickedSubscription) {
            this.simpleToasterClickedSubscription.unsubscribe();
          }
        }
      }
    );
    this.initializeApp();
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  async ngOnInit() {
    if (!this.zendeskService.isInitialized) {
      await this.zendeskService.initZendesk();
      // console.log('Zendesk app.comp initIALIZED', this.zendeskService.isInitialized);
    }
    if (!this.zendeskService.isInitialized) {
      alert('Erreur lors de l\'initialisation de Zendesk.\nLe contact avec le support est impossible.');
    }
  }

  @HostListener('mousedown', ['$event']) mouseDown(event) {
    this.lastClick = event.target;
  }

  @HostListener('mouseup', ['$event']) mouseUp(event) {
    if (!this.isCordova) {
      const activeElement = document.activeElement;
      if (activeElement && (this.inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) || (!!this.lastClick && (this.lastClick.className === 'evs_option' || this.lastClick.className === 'icon ion-ios-close'))) {
        if (this.hideFooterNav) {
          this.hideFooterNav = false;
        } else {
          this.hideFooterNav = true;
        }
      } else {
        this.hideFooterNav = false;
      }
      this.lastClick = null;
    }
  }

  @HostListener('touchstart', ['$event']) touchStart(event) {
    this.lastClick = event.target;
  }

  @HostListener('touchend', ['$event']) touchEnd(event) {
    const activeElement = document.activeElement;
    // console.log(this.inputs);
    // console.log(this.lastClick);
    if (activeElement && (this.inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) || (!!this.lastClick && (this.lastClick.className === 'evs_option' || this.lastClick.className === 'icon ion-ios-close'))) {
      if (this.hideFooterNav) {
        this.hideFooterNav = false;
      } else {
        this.hideFooterNav = true;
      }
    } else {
      this.hideFooterNav = false;
    }
    this.lastClick = null;
  }

  async checkIfWootricCanBeShown() {
    // console.log('checkIfWootricCanBeShown');
    // console.log('checkIfWootricCanBeShown stopWootricLoop', this.stopWootricLoop);
    if (!this.stopWootricLoop) {
      this.student = this.studentService.student;
      this.configUser = await this.configUserService.getUserConfig(this.student);
      this.configApp = await this.configService.getAppConfig();
      // const forceWootric = (!!this.student.remoteId && this.student.remoteId !== 'GUEST'); // FOR TESTS
      const forceWootric = false; // IN PROD
      if (forceWootric) {
        this.configApp.hasMadeWootric = false;
        this.configApp = await this.configService.setAppConfig(this.configApp);
      }
      const canBeShown = (
        (!!this.student.remoteId && this.student.remoteId !== 'GUEST') &&
        ((!!this.configApp && !this.configApp.iOSATTSliderShown) || !this.configApp) &&
        ((!!this.configUser && !this.configUser.showPostRegistration) || !this.configUser) &&
        ((!!this.configApp && !this.configApp.showPostRegistration) || !this.configApp)
      );
      // console.log('checkIfWootricCanBeShown student.remoteId', this.student.remoteId);
      // console.log('checkIfWootricCanBeShown configUser', this.configUser);
      // console.log('checkIfWootricCanBeShown configApp', this.configApp);
      // console.log('checkIfWootricCanBeShown canBeShown', canBeShown);
      // console.log('checkIfWootricCanBeShown canBeShown', canBeShown);
      if (canBeShown) {
        const userProperties = await this.studentService.fetchUserProperties();
        // console.log('checkIfWootricCanBeShown userProperties', userProperties);

        // console.log('featureFlagsService.flags[wootric]', this.featureFlagsService.flags['wootric']);
        if (!!this.featureFlagsService.flags && !!this.featureFlagsService.flags['wootric'] && this.featureFlagsService.flags['wootric']) {
          const allowedRoutesForWootric = [
            'accueil',
            'dashboard',
            'code',
            'conduite',
            'profil',
          ];
          const authWootric = (!!this.currentRoute && allowedRoutesForWootric.indexOf(this.currentRoute) !== -1) ? true : false;

          // console.warn('authWootric', authWootric);
          // console.log('wootric userProperties', userProperties);
          // console.log('wootric sessions', (userProperties.status === 'registered' && (userProperties.user_series_session_started > 3 || userProperties.user_series_session_completed > 0)));
          // console.log('wootric check', authWootric &&
          // !!userProperties && !!userProperties.status && !!userProperties.user_series_session_started &&
          // userProperties.status !== 'teacher' &&
          // userProperties.status !== 'guest' &&
          // (
          //   userProperties.status !== 'registered' ||
          //   (userProperties.status === 'registered' && (userProperties.user_series_session_started > 3 || userProperties.user_series_session_completed > 0))
          // ));

          if (
            forceWootric || (
              authWootric &&
              !!userProperties && !!userProperties.status && !!userProperties.user_series_session_started &&
              userProperties.status !== 'teacher' &&
              userProperties.status !== 'guest' &&
              (
                userProperties.status !== 'registered' ||
                (userProperties.status === 'registered' && (userProperties.user_series_session_started > 3 || userProperties.user_series_session_completed > 0))
              )
            )
          ) {
            this.stopWootricLoop = true;
            this.configService.switchBoolean('hasMadeWootric'); // don't need to await
            let userEnv = null;
            if (this.platform.is('cordova') && this.platform.is('ios')) {
              userEnv = 'iOS';
            } else if (this.platform.is('cordova') && this.platform.is('android')) {
              userEnv = 'Android';
            } else  {
              userEnv = 'web_app';
            }
            // DATE TO CHANGE
            const date = this.dateToFormatFr(userProperties.confirmed_at);
            (window as any).wootricSettings.email = this.student.remoteId;
            (window as any).wootricSettings.external_id = this.student.remoteId;
            (window as any).wootricSettings.created_at = Math.round(date.valueOf() / 1000);
            (window as any).wootricSettings.properties = {};
            (window as any).wootricSettings.properties.user_status = userProperties.status;
            (window as any).wootricSettings.properties.client_type = userProperties.client_type;
            (window as any).wootricSettings.properties.environment = userEnv;
            (window as any).wootricSettings.properties.departement = userProperties.department;
            (window as any).wootricSettings.properties.user_said_in_ae = userProperties.declared_registered_in_driving_school;
            (window as any).wootricSettings.properties.user_series_started = userProperties.user_series_session_started;
            (window as any).wootricSettings.properties.user_series_completed = userProperties.user_series_session_completed;
            (window as any).wootricSettings.properties.user_signed_up_from = userProperties.signed_up_from;
            (window as any).wootricSettings.properties.hours_passed_lessons = userProperties.hours_passed_lessons;
            (window as any).wootric('run');
            // console.log('wootric launched');
          }
        }
      } else {
        if ((!!this.configApp && !!this.configApp.hasMadeWootric && this.configApp.hasMadeWootric) || (!!this.student.remoteId && this.student.remoteId === 'GUEST')) {
          // console.log('checkIfWootricCanBeShown else stopWootricLoop');
          this.stopWootricLoop = true;
        }
        if (!this.stopWootricLoop) {
          this.cptLoopWootric++;
          if (this.cptLoopWootric < this.maxLoopWootric) {
            setTimeout(() => {
              this.checkIfWootricCanBeShown();
            }, 3000);
          } else {
            this.stopWootricLoop = true;
          }
        }
      }
    }
  }

  async redirectUser(link) {

    let authParams = null;
    let url = location.toLocaleString();
    let isSignedIn = false;

    if (!!url) {
      authParams = this.getAuthParams(url);
    }
    if (!!authParams && Object.keys(authParams).length !== 0 && this.checkKeys(authParams)) {
      return false;
    } else {
      isSignedIn = await this.studentService.isSignedInHard();

      if (link === 'splash') {
        // console.log('ANTICOUILLONS');
        return false;
      }
      // console.log('isSignedIn routegard => ', isSignedIn);
      
      if (isSignedIn) {
        this.openLink(link);
        return false;
      }
      
      if (this.doesNeedLogin(link)) {
        await this.storage.set('redirectAfterLogIn', link);
        // console.log('redirect saved routeguard => ', link);
        this.openLink('login');
        return true;
      }
      
      this.openLink(link);
      return false;
    }
  }

  getAuthParams(url: string): any {
    const link = url.slice(url.lastIndexOf(`/`) + 1, url.length);
    if (url.split('?')[1] === undefined) {
      return null;
    }
    const args = url.split('?')[1].split('#')[0];
    if (args === undefined) {
      return null;
    } else {
      const obj = {};
      for (const arg of args.split('&')) {
        obj[arg.split('=')[0]] = arg.split('=')[1];
      }
      obj[`link`] = link;
      return obj;
    }
  }

  checkKeys(authParams: any) {
    if (!!authParams) {
      if ((Object.keys(authParams).includes("email") || (Object.keys(authParams).includes("id"))) &&
          Object.keys(authParams).includes("created_at") &&
          Object.keys(authParams).includes("token") &&
          Object.keys(authParams).includes("link")) {
        return true;
      }
    }
    return false;
  }

  getLink(link: string) {
    if (link[0] === '/') {
      link = link.substring(1);
    }
    return link;
  }

  doesNeedLogin(link: string) {
    for (const path of this.needLogIn) {
      if (!!path && path === link) {
        return true;
      }
    }
    return false;
  }

  initiateSpeedDetection() {
    setTimeout(() => {
      this.measureConnectionSpeed();
    }, 1);
  }

  measureConnectionSpeed() {
    let startTime: any, endTime: any;
    const download = new Image();
    download.onload = () => {
      // DATE TO CHANGE
      endTime = this.dateToFormatFr().valueOf();
      this.showResults(startTime, endTime);
    };

    download.onerror = (err: any) => {
      this.sendDownloadResults(null);
    };

    // DATE TO CHANGE
    startTime = this.dateToFormatFr().valueOf();
    const cacheBuster = '?nnn=' + startTime;
    // console.log(this.imageAddr + cacheBuster);
    download.src = this.imageAddr + cacheBuster;
  }

  showResults(startTime: any, endTime: any) {
    const duration = (endTime - startTime) / 1000;
    const bitsLoaded = this.downloadSize * 8;
    const speedBps: any = (bitsLoaded / duration).toFixed(2);
    const speedKbps: any = (speedBps / 1024).toFixed(2);
    const speedMbps = (speedKbps / 1024).toFixed(2);
    this.sendDownloadResults({
      bps: speedBps + ' bps',
      kbps: speedKbps + ' kbps',
      Mbps: speedMbps + ' Mbps'
    });
  }

  sendDownloadResults(result: any) {
    this.report.environment.internetSpeed = result;
    this.easyDebugService.sendReport(this.report);
  }

  async generateReport() {
    // reset
    this.report = {};

    const student = this.studentService.student;
    const userLifeCycle = this.studentService.getUserLifeCycle(student); // if student not ready return guest
    const isSignedInHard = await this.studentService.isSignedInHard();

    // USER
    this.report.user = {};
    this.report.user.userRemoteId = student.remoteId;
    this.report.user.userLifeCycle = userLifeCycle;
    this.report.user.isSignedInHard = isSignedInHard;

    const storageKeys = await this.storage.keys();

    // ENVIRONMENT
    this.report.environment = {};
    this.report.environment.cordova = this.platform.is('cordova');
    this.report.environment.cordovaVersion = this.device.cordova;
    this.report.environment.platforms = this.platform.platforms();
    this.report.environment.deviceUUID = this.device.uuid;
    this.report.environment.deviceModel = this.device.model;
    this.report.environment.devicePlatform = this.device.platform;
    this.report.environment.deviceVersion = this.device.version;
    this.report.environment.deviceManufacturer = this.device.manufacturer;
    this.report.environment.deviceSerial = this.device.serial;
    this.report.environment.orientation = (this.platform.isLandscape()) ? 'landscape' : (this.platform.isPortrait()) ? 'portrait' : null;
    this.report.environment.width = this.platform.width();
    this.report.environment.height = this.platform.height();
    this.report.environment.userAgent = window.navigator.userAgent;
    this.report.environment.appCodeName = window.navigator.appCodeName;
    this.report.environment.appName = window.navigator.appName;
    this.report.environment.appVersion = environment.version;
    this.report.environment.diskSpace = (this.platform.is('cordova')) ? await this.storage.getDiskSpace() : null;
    this.report.environment.nbKeysInStorage = storageKeys.length;

    // STORAGE
    this.report.storage = {};
    const storageData = await Promise.all(storageKeys.map(e => this.storage.get(e)));
    for (let i = 0, len = storageKeys.length; i < len; i++) {
      this.report.storage[storageKeys[i]] = storageData[i];
    }

    // storageKeys.forEach(async element => {
    //   this.report.storage[element] = await this.storage.get(element);
    // });

    // DOWNLOADED ASSETS
    if (this.platform.is('cordova')) {
      const downloadedImgs = await this.file.listDir(this.file.dataDirectory, 'Imgs').catch(
        () => {
          return [];
        }
      );

      const downloadedAudios = await this.file.listDir(this.file.dataDirectory, 'Audios').catch(
        () => {
          return [];
        }
      );

      const downloadedVideos = await this.file.listDir(this.file.dataDirectory, 'Videos').catch(
        () => {
          return [];
        }
      );

      this.report.downloadedAssets = {};
      this.report.downloadedAssets.imgs = downloadedImgs;
      this.report.downloadedAssets.audios = downloadedAudios;
      this.report.downloadedAssets.videos = downloadedVideos;
    } else {
      this.report.downloadedAssets = null;
    }

    // LOGS
    this.report.logs = this.easyDebugService.getLogs();

    this.initiateSpeedDetection();
  }

  sendReport() {
    this.easyDebugService.setRecordingMode('send');
  }

  checkIfReportNeedToBeSent() {
    const timeSpentOnSplash = this.pageLoaderService.getTimeSpent();
    const timeBeforeSendingReport = 45;
    const timeSplashDoneButSup = 40;
    // console.log('timeSpentOnSplash', timeSpentOnSplash);
    if (!this.softDeployService.update) {
      if (!!timeSpentOnSplash) {
        clearInterval(this.timeInterval);
        this.splashScreenWentWell = true;
        if (timeSpentOnSplash >= timeSplashDoneButSup) {
          this.sendSilentReport();
        } else {
          // no need to send report splash screen ok - then clean logs
          // console.log('No need to send a report splash screen ok - then clean logs');
          this.clearEasyDebugLogs();
        }
      } else {
        // DATE TO CHANGE
        const now = this.dateToFormatFr().valueOf();
        const timeSpent = Math.round((now - this.pageLoaderService.getStartTimeOnSplash()) / 1000);
        // console.log('timeSpentOnSplash with no response', timeSpent);
        if (timeSpent >= timeBeforeSendingReport) {
          clearInterval(this.timeInterval);
          this.sendSilentReport();
        }
      }
    }
  }

  clearEasyDebugLogs() {
    const logs = this.easyDebugService.getLogs();
    // console.log('show logs before cleaning', logs);
    this.easyDebugLogger.isEasyDebugActive = false;
    this.easyDebugLogger.logs = [];
    this.splashCalled = false;
  }

  async sendSilentReport() {
    // console.log('sendSilentReport > silentReportSent', this.silentReportSent);
    const isSignedInHard = await this.studentService.isSignedInHard();

    if (!this.networkStatus?.isOffline()) {
      if (!this.silentReportSent) {
        if (isSignedInHard) {
          this.silentReportSent = true;
          this.recordingMode = this.easyDebugService.getRecordingMode();
          this.showModalSendSilentReportDebugMode();
          await this.generateReport();
        } else {
          this.clearEasyDebugLogs();
          console.log('SilentReport not sent : User not connected!');
        }
      } else {
        this.clearEasyDebugLogs();
        console.log('SilentReport already sent!');
      }
    } else {
      this.clearEasyDebugLogs();
      console.log('SilentReport not sent : no internet!');
    }
  }

  async resetSync() {
    this.easyDebugService.setRecordingMode(null);
    await this.storage.clear();
    this.appInitService.init0();
    this.openLink('splash', { forceRoot: true });
  }

  hideModalSendReportDebugMode() {
    this.displayModalSendReportDebugMode = false;
  }

  showModalSendReportDebugMode() {
    this.displayModalSendReportDebugMode = true;
  }

  hideModalSendSilentReportDebugMode() {
    this.displayModalSendSilentReportDebugMode = false;
  }

  showModalSendSilentReportDebugMode() {
    this.displayModalSendSilentReportDebugMode = true;
  }

  goBackHistory() {
    const debug = true;
    if ((this.platform.is('android') && this.platformReady) || debug) {
      // FOR GAMIFICATION SPECIFIC ROUTING SYSTEM
      if (this.navigationService.lastLink === 'reussites') {
        return;
      }

      if (this.navigationService.userNavigation.length > 1) {
        const url = this.router.url.replace(/^\//, '');
        if (
          !!this.navigationService.userNavigation[this.navigationService.userNavigation.length - 2] &&
          !!this.navigationService.userNavigation[this.navigationService.userNavigation.length - 2].link &&
          this.navigationService.userNavigation[this.navigationService.userNavigation.length - 2].link !== url
        ) {
          let backUrl = this.navigationService.userNavigation[this.navigationService.userNavigation.length - 2].link;
          if (backUrl.includes('splash') || this.navigationService.iframeAlreadyOpen) {
            backUrl = null;
          }
          if (url.includes('series')) {
            const questionIndex = this.activatedRoute.snapshot.paramMap.get('questionIndex');
            if (Number(questionIndex) > 0 || (url.includes('results') && !url.includes('last-results'))) {
              if (backUrl.includes('entrainements')) {
                backUrl = 'series/entrainements';
              } else if (backUrl.includes('examens')) {
                backUrl = 'series/examens';
              } else if (backUrl.includes('thematiques')) {
                backUrl = 'series/thematiques';
              } else if (backUrl.includes('erreurs')) {
                backUrl = 'series/erreurs/buffer';
              }
            }
          }
          if (!!backUrl) {
            this.navigationService.userNavigation.splice(this.navigationService.userNavigation.length - 2, 2);
            // NG ZONE
            this.ngZone.run(() => {
              // console.log('backUrl', backUrl);
              this.navigationService.open(backUrl, null);
            });
          } else {
            this.closeIframe();
          }
        } else {
          // console.log('nav length - 2 || link === currentUrl', this.navigationService.userNavigation);
          this.closeIframe();
        }
      } else {
        // console.log('nav length < 1', this.navigationService.userNavigation);
        this.closeIframe();
      }
    }
  }

  closeIframe() {
    if (this.navigationService.iframeAlreadyOpen) {
      // close iframe
      this.modalController.dismiss();
      this.navigationService.iframeAlreadyOpen = false;
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      const transformed = await this.storage.get('transformed');

      if (!!transformed && transformed) {
        this.softDeployService.skipSoftDeploy = true;
      }

      this.deeplinks.routeWithNavController(this.navigationService.navController,
        {}
      ).subscribe(async (match) => {
        // match.$route - the route we matched, which is the matched entry from the arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        // console.log('MATCH => ', match);
        if (match.$link.url.includes('user_agent')) {
          this.studentService.easyDebugAutoLogUrl = match.$link.url;
          if (match.$link.url.includes('debug_id')) {
            this.devDebugModeService.debugMode = true;
            this.softDeployService.skipSoftDeploy = true;
          }
          await this.appInitService.init2().then(
            res => {
              this.ngZone.run(() => {
                this.openLink('code');
              });
            }
          )
        } else if (!!match.$link.path) {
          // Used Substring but it was confusing, replacing string is more readable
          const link = match.$link.path.replace('/ul/', '');
          if (!!link) {
            this.navigationService.universalLink = link;
            this.ngZone.run(() => {
              this.openLink(link);
            });
          }
        }
        // console.log('Successfully matched route', match);
      },
      (nomatch) => {
        // console.log('NOMATCH => ', nomatch);
        // nomatch.$link - the full link data
        // console.log('Got a deeplink that didn\'t match', nomatch);
      });
      this.versionMessagesSub = this.softDeployService.versionMessagesObs$.subscribe(
        message => {
          this.toasterCustomService.create({
            text: message.message,
            type: 'sticky',
            ctaText: 'OK',
            ctaLink: message.openStore ? 'store' : 'close'
          });
        }
      );

      if (this.platform.is('cordova')) {

        this.platformReady = true;
        this.statusBar.overlaysWebView(false);
        this.statusBar.styleDefault();
        this.statusBar.backgroundColorByHexString('white');
        this.statusBar.show();
        this.splashScreen.hide();
        console.log('SUBSCRIBE');
        this.siteOpenerService.return$.subscribe(
          event => {
            // console.log('EVENT => ', JSON.stringify(event));
            if (!!event?.link && !!event?.close && !this.navigationService.initLaunched) {
              this.appInitService.runLightInit().then(
                res => {
                  // console.log('LINK => ', event.link);
                  this.navigationService.initLaunched = true;
                  this.openLink(event.link);
                }
              )
            }
          }
        )
      }
      let update = false;
      let redirectCheck = false;
      // console.log('redirectCheck => ', redirectCheck);
      let init2 = false;
      let init3 = false;
      const sub = this.appInitService.onStepChange().subscribe(
        async (state) => {
          // console.log('app step init', step);
          if (state?.init >= 2 && state?.stepDone && !init2) {
            init2 = true;
            if (!!this.currentRoute) {
              await this.redirectUser(this.currentRoute);
            }

            if (this.platform.is('cordova') && !update) {
              update = true;
              if (!this.softDeployService.skipSoftDeploy) {
                this.softDeployService.checkUpdate();
              }
            }
          }
          if (state?.init >= 3 && state.stepDone && !init3) {
            init3 = true;
            if (!redirectCheck) {
              redirectCheck = true;
              this.kameleoonService.kamRedirectObs$.subscribe(
                redirect => {
                  if (!!redirect && !!redirect.link && !!redirect.target) {
                    if (redirect.target === 'inApp') {
                      this.openLink(redirect.link);
                    }
                    if (redirect.target === 'outApp') {
                      this.openLink(redirect.link, {forcedSiteOpener: true, forceOutLink: true});
                    }
                  }
                }
              );
            }
          }
          if (state?.initDone) {
          // console.log('app init done', done);
            if (!this.appInitdone) {
              this.appInitdone = true;

              if (this.isCordova && this.syncCdr.syncSeriesToSync > 0) {
                const statObj = {
                  nbSeriesToSynchro: this.syncCdr.syncSeriesToSync,
                  nbSeriesSynchronized: this.syncCdr.syncSeriesSynced,
                  loadTime: this.syncCdr.syncTimeSpent
                };
                this.statsService.send({ name: 'init:sync_cdr', payload: statObj });
                this.syncCdr.resetSyncCdrStats();
              }

              if (this.isCordova) {
                this.checkLessonsRating(true);
              } else {
                this.checkLessonsRating();
              }
            }
          }
        }
      );
      await this.appInitService.run();
      // console.log('app this.studentService.logId', this.studentService.logId);
      if (!!this.studentService.logId) {
        await this.devDebugModeService.transformStorageData(this.studentService.logId);
        // console.log('transformStorageData done > reload');
        window.location.reload();
      }
    });
  }

  async checkLessonsRating(force = false) {
    if (!this.networkStatus?.isOffline() && this.currentRoute !== 'gmap' && this.currentRoute !== 'gmap-non-desservies' && this.currentRoute !== 'gmap-new') {
      this.student = await this.studentService.getProfil();
      this.configUser = await this.configUserService.getUserConfig(this.student);
      const unratedLessons = this.lessonsService.newUnratedLessons;
      const userLifeCycle = this.studentService.getUserLifeCycle(this.student);
      const canDrive = (userLifeCycle.isUserCanDrive || userLifeCycle.isUserDrivingSuccess);

      const isDebug = false; // DEBUG

      // console.log('this.currentRoute', this.currentRoute);
      // console.log('unratedLessons ', unratedLessons);
      // console.log('canDrive', canDrive);

      // LESSON RATING
      if (((isDebug) && this.currentRoute !== 'notation') || (this.currentRoute !== 'notation' && !!unratedLessons && unratedLessons.length > 0 && canDrive)) {
        // 1h = (60 * 1000) * 60 = 3600000
        const oneHour = (isDebug) ? 10000 : 3600000; // 10s mode debug
        const now = Date.now();

        if (!this.configUser.notationModalShownLastDate) { // never shown
          // console.log('never shown');
          this.configUser.notationModalShownLastDate = now;
          await this.configUserService.setUserConfig(this.student, this.configUser);
          this.openModalPub(unratedLessons.length.toString(), 'modalNotationLessons');
        } else if (!!this.configUser.notationModalShownLastDate) { // last shown
          // console.log('last shown', now, this.configUser.notationModalShownLastDate, now - this.configUser.notationModalShownLastDate);
          if (force || (now - this.configUser.notationModalShownLastDate) >= oneHour) {
            this.configUser.notationModalShownLastDate = now;
            await this.configUserService.setUserConfig(this.student, this.configUser);
            this.openModalPub(unratedLessons.length.toString(), 'modalNotationLessons');
          }
        }
      }
    }
  }

  launchToaster(config) {
    this.toasterService.create(config);
  }

  async openModalPub(nbGoodAnswers?: any, type?: any, noClose = false) {
    if (!type) {
      type = 'marketingRegular';
    }
    const props = {
      'type': type,
      nbGoodAnswers: nbGoodAnswers,
      bannerInfo: {
        item_id: 'rating_lesson',
        item_promo_type: 'regular',
        item_location_id: 'notation',
        item_location_format: 'modale',
        item_promo_content: '',
      }
    };

    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        backdropDismiss: false,
        componentProps: props
      });
    } else {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        componentProps: props
      });
    }
    return await modal.present();
  }

  async openModalPack(bannerInfo?: any, type?: string, noClose = false, customClass = '') {
    if (!type) {
      type = 'marketingRegular';
    }
    let props;
    if (!!bannerInfo) {
      props = {
        'type': type,
        bannerInfo: {
          item_id: bannerInfo.itemId,
          item_promo_type: bannerInfo.itemPromoType,
          item_location_id: bannerInfo.itemLocationId,
          item_location_format: bannerInfo.itemLocationFormat,
          item_promo_content: bannerInfo.itemPromoContent,
        }
      };
    } else {
      props = {
        'type': type,
        bannerInfo: null
      };
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        backdropDismiss: false,
        componentProps: props,
        cssClass: customClass,
      });
    } else {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        componentProps: props,
        cssClass: customClass,
      });
    }
    return await modal.present();
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    }
    return await modal.present();
  }

  closeModals() {
    const modals = document.getElementsByTagName('ion-modal');
    while (modals.length > 0) {
      modals[0].parentNode.removeChild(modals[0]);
    }
  }

  async openLink(link, options?) {
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
