import { AnswersService } from './answers.service';
import { Student } from './../models/student';
import { Serie } from '../models/serie';
import { Session } from '../models/session';
import { Answer } from '../models/answer';

import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';
import { SeriesService } from './series.service';
import { QuestionsService } from './questions.service';
import { SessionsService } from './sessions.service';
import { StudentService } from './student.service';
import { NetworkStatusService } from './network-status.service';
import { SentryService } from './sentry.service';
import { UserErrorHandlerService } from './user-error-handler.service';

import { switchMap, take, mergeMap } from 'rxjs/operators';
import { of, Observable, from } from 'rxjs';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
// import { DevDebugModeService } from '../app.services';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
@Initialable({step: `init5`, initializer: `onInit`})
@EasyDebugDecorator
export class SyncCDRService {

  syncedOfflineSessions = [];
  syncSeriesToSync = 0;
  syncSeriesSynced = 0;
  syncStartTimer = 0;
  syncEndTimer = 0;
  syncTimeSpent = 0;

  constructor(
    private seriesService: SeriesService,
    private questionsService: QuestionsService,
    private sessionsService: SessionsService,
    private studentService: StudentService,
    private answersService: AnswersService,
    private networkService: NetworkStatusService,
    private platform: Platform,
    private storageService: StorageService,
    private sentryService: SentryService,
    private userErrorHandlerService: UserErrorHandlerService,
  ) {
  }

  async onInit() {
    const student = this.studentService.student;
    return await new Promise((resolve, reject) => {
      this.sync(student).then(
        async series => {
          if (!!series.errorCode && series.errorCode === 'E301') {
            this.userErrorHandlerService.addError({criticity: 20, service: 'sync-cdr', platform: 'device', data: series.errorOriginal, errorCode: 'scss'});
          }
          await this.reconcileApisData();
          resolve(series);
        }
      ).catch(
        err => {
          console.log('sync-cdr sync()', err);
          this.userErrorHandlerService.addError({criticity: 20, service: 'sync-cdr', platform: 'device', data: err, errorCode: 'scss'});
          this.sentryService.sendToSentry('Sync CDR: Failed: ' + JSON.stringify(err), this.studentService.student);
        }
      );
    });
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  async reconcileApisData() {
    const student = this.studentService.student;
    let series = [];
    let sessions = [];
    const resSeriesList = await this.seriesService.seriesContext.getSeriesList(student);
    if (!!resSeriesList && !!resSeriesList.errorCode && resSeriesList.errorCode === 'E301') {
      series = [];
    } else {
      series = resSeriesList;
    }
    const resgetSessions = await this.sessionsService.sessionsContext.getSessions(student);
    if (!!resgetSessions && !!resgetSessions.errorCode && resgetSessions.errorCode === 'E301') {
      sessions = [];
    } else {
      sessions = resgetSessions;
    }
    // order sessions by date desc
    sessions.sort((a, b) => {
      // DATE TO CHANGE
      return +new Date(b.lastAnsweredAt) - +new Date(a.lastAnsweredAt);
    });
    if (!!sessions && sessions.length > 0) {
      await this.updateSeriesLastSessions(student, series, sessions);
    }
  }

  async updateSeriesLastSessions(student: any, series: any, sessions: any) {
    const updatedSeries: Serie[] = [];
    if (!!series && series.length > 0) {
      for (const serie of series) {
        const updatedSerie: Serie = new Serie(serie);
        const _lastTrainingSessions: any[] = JSON.parse(JSON.stringify(sessions));
        const _lastExamSessions: any[] = JSON.parse(JSON.stringify(sessions));
        const lastTrainingSessions = _lastTrainingSessions.filter(elt => elt._serieId === serie.id && elt._mode === 'Cdr::TrainingSession' && elt._answersCount > 0);
        const lastExamSessions = _lastExamSessions.filter(elt => elt._serieId === serie.id && elt._mode === 'Cdr::ExamSession' && elt._answersCount > 0);
        const lastTrainingSession = (!!lastTrainingSessions && lastTrainingSessions.length > 0) ? lastTrainingSessions[0]._id : null;
        const lastExamSession = (!!lastExamSessions && lastExamSessions.length > 0) ? lastExamSessions[0]._id : null;
        updatedSerie.lastSessions = [lastTrainingSession, lastExamSession];
        updatedSeries.push(updatedSerie);
      }
      await this.seriesService.seriesContext.setSeriesList(updatedSeries, student);
    }
  }

  async updateSerieData(student: any, serie: any, sessions: any) {
    if (!!serie && !!student) {
      const updatedSerie: Serie = new Serie(serie);
      if (!sessions) {
        const resgetSessions = await this.sessionsService.sessionsContext.getSessions(student);
        if (!!resgetSessions && !!resgetSessions.errorCode && resgetSessions.errorCode === 'E301') {
          sessions = [];
        } else {
          sessions = resgetSessions;
        }
      }
      const _lastTrainingSessions: any[] = JSON.parse(JSON.stringify(sessions));
      const _lastExamSessions: any[] = JSON.parse(JSON.stringify(sessions));
      const lastTrainingSessions = _lastTrainingSessions.filter(elt => elt._serieId === serie.id && elt._mode === 'Cdr::TrainingSession' && elt._answersCount > 0);
      const lastExamSessions = _lastExamSessions.filter(elt => elt._serieId === serie.id && elt._mode === 'Cdr::ExamSession' && elt._answersCount > 0);
      const lastTrainingSession = (!!lastTrainingSessions && lastTrainingSessions.length > 0) ? lastTrainingSessions[0]._id : null;
      const lastExamSession = (!!lastExamSessions && lastExamSessions.length > 0) ? lastExamSessions[0]._id : null;
      updatedSerie.lastSessions = [lastTrainingSession, lastExamSession];
      return await this.seriesService.seriesContext.setSerie(updatedSerie, student);
    }
    return serie;
  }

  formatDebug(data: any) {
    let str = '';
    if (!!data) {
      if (Array.isArray(data)) {
        data.forEach(function(entry: object) {
          str = (str !== '') ? str + '\n ######### \n' : str;
          if (typeof entry === 'object' &&
            !Array.isArray(entry) &&
            entry !== null
          ) {
            for (const [key, value] of Object.entries(entry)) {
              if (!!key && !!value) {
                if ((key === '_answers' || key === 'answers') && Array.isArray(value)) {
                  str += `${key}: ${value.length} - `;
                } else {
                  str += `${key}: ${JSON.stringify(value)} - `;
                }
              }
            }
          }
        });
      } else {
        if (typeof data === 'object' &&
          !Array.isArray(data) &&
          data !== null
        ) {
          for (const [key, value] of Object.entries(data)) {
            if (!!key && !!value) {
              if ((key === '_answers' || key === 'answers') && Array.isArray(value)) {
                str += `${key}: ${value.length} - `;
              } else {
                str += `${key}: ${JSON.stringify(value)} - `;
              }
            }
          }
        } else {
          str += JSON.stringify(data) + ' - ';
        }
      }
    }
    return str;
  }

  async sync(student: Student): Promise<any> {
    const locked = !(await this.storageService.checkEnougthSpace());
    const transformed = await this.storageService.get('transformed');
    if (!!student && this.networkService?.isOffline() || !this.platform.is('cordova') || locked || transformed) {
      return of(null);
    }
    return from(of(null)).pipe(
      switchMap(
        () => {
          this.syncStartTimer = Date.now();
          // console.log('PREPARE TO SYNC');
          return this.handleLocalSessions(student);
        }
      ),
      switchMap(
        async () => {
          let lastUpdate = null;
          const sessions = await this.sessionsService.sessionsContext.getSessions(student, true);
          if (!!sessions && sessions.length > 0) {
            const sessionsSorted = sessions.sort((eltf, elts) => {
              // DATE TO CHANGE
              return +new Date(elts.lastAnsweredAt) - +new Date(eltf.lastAnsweredAt);
            });
            lastUpdate = sessionsSorted.shift().lastAnsweredAt;
          }
          return this.syncSessionsPromise(lastUpdate, student);
        }
      ),
      switchMap(
        async () => {
          this.syncSeriesSynced = this.syncedOfflineSessions.length;
          await this.removeOfflineSessionsSynced(student, this.syncedOfflineSessions);
          let lastUpdate = null;
          // console.log('sync-cdr sync-series');
          const series = await this.seriesService.seriesContext.getSeries(student, true);

          // console.log('series => ', JSON.parse(JSON.stringify(series)));
          if (!!series && series.length > 0) {
            const seriesSorted = series.sort((eltf, elts) => {
              // DATE TO CHANGE
              return +new Date(elts.updatedAt) - +new Date(eltf.updatedAt);
            });
            // console.log('series sorted => ', JSON.parse(JSON.stringify(seriesSorted)));
            lastUpdate = seriesSorted.shift().updatedAt;
            // console.log('lastupdatedat => ', lastUpdate);
          }
          return this.syncSeriesPromise(lastUpdate, student);
        }
      ),
      switchMap(
        async series => {
          // console.log('sync-cdr sync series', JSON.parse(JSON.stringify(series)));
          // en fait le get va ensuite faire un set => moche !!
          await this.sessionsService.getSessionsType(student);
          return series;
        }
      ),
      switchMap(
        async series => {
          await this.updateLastSessions(student);
          this.syncEndTimer = Date.now();
          this.syncTimeSpent = (this.syncEndTimer - this.syncStartTimer) / 1000;
          return series;
        }
      ),
      switchMap(
        async series => {
          let lastUpdatedQuestion = null;
          const questions = await this.questionsService.questionsContext.getQuestions(student, true);
          // console.log('local questions => ', questions);
          if (!!questions && questions.length > 0) {
            const questionsSorted = questions.sort((eltf, elts) => {
              // DATE TO CHANGE
              return +new Date(elts.updatedAt) - +new Date(eltf.updatedAt);
            });
            lastUpdatedQuestion = questionsSorted.shift().updatedAt;
            // console.log('last update question => ', lastUpdatedQuestion);
            if (!!lastUpdatedQuestion) {
              await this.questionsService.syncQuestions(student, lastUpdatedQuestion, questions);
            }
          } else {
            // donc en gros si pas de questions en local tu récup tout et tu save dfans le storage ?
            // meme en guest !!!
            await this.questionsService.questionsContext.getQuestions(student);
          }
          return series;
        }
      ),
      switchMap(
        async series => {
          if (!!student.status && student.status !== 'guest') {
            await this.seriesService.syncThemes(student);
          }
          return series;
        }
      )
    ).toPromise();
  }

  resetSyncCdrStats() {
    this.syncSeriesToSync = 0;
    this.syncSeriesSynced = 0;
    this.syncStartTimer = 0;
    this.syncEndTimer = 0;
    this.syncTimeSpent = 0;
  }

  syncSessionsPromise(updatedAt: string, student: Student): Promise<Session[]> {
    if (!!student && !!updatedAt) {
      return this.sessionsService.syncSessions(updatedAt, student).toPromise();
    }
    return new Promise(resolve => resolve([]));
  }

  syncSeriesPromise(updatedAt: string, student: Student): Promise<Serie[]> {
    if (!!student && !!updatedAt) {
      return this.seriesService.syncSeries(updatedAt, student).toPromise();
    }
    return new Promise(resolve => resolve([]));
  }

  handleLocalSessions(student: Student): Observable<Session[]> {
    if (!!student) {
      return from(of(null)).pipe(
        switchMap(
          async () => {
            if (!!student.status && student.status !== 'guest') {
              const sessionsToCreate = await this.getSessionsToCreate(student);
              this.syncSeriesToSync = sessionsToCreate.length;
              // console.log('sync-cdr handleLocalSessions ', JSON.parse(JSON.stringify(sessionsToCreate)));
              return sessionsToCreate;
            }
            return [];
          }
        ),
        switchMap(
          sessionsToCreate => {
            if (!!sessionsToCreate && sessionsToCreate.length > 0) {
              return this.createOfflineSessions(sessionsToCreate, student);
            }
            return of([]);
          }
        )
      );
    }
    return of([]);
  }

  createOfflineSessions(sessionsToCreate: Session[], student: Student): Promise<Session[]> {
    if (!!student && !!sessionsToCreate && sessionsToCreate.length > 0) {
      // console.log('sync-cdr createOfflineSessions ', JSON.parse(JSON.stringify(sessionsToCreate)));
      return new Promise((resolve, reject) => {
        const sessions = [];
        let count = 0;
        this.fetchOfflineSessions(sessionsToCreate, student).subscribe(
          async sessionsReturned => {
            // sessionsReturned is object to link sessionToCreate to sessionCreated
            // because count can be incremented before post response
            // console.log('sync-cdr createOfflineSessions sessionsReturned after post', JSON.parse(JSON.stringify(sessionsReturned)));
            // console.log('sync-cdr createOfflineSessions sessionsReturned !!sessionToCreate', !!sessionsReturned.sessionToCreate);
            // console.log('sync-cdr createOfflineSessions sessionsReturned !!sessionToCreate.answers', !!sessionsReturned.sessionToCreate.answers);
            // console.log('sync-cdr createOfflineSessions sessionsReturned !!sessionCreated', !!sessionsReturned.sessionCreated);
            // console.log('sync-cdr createOfflineSessions sessionsReturned !!sessionCreated.answers', !!sessionsReturned.sessionCreated.answers);
            // console.log('sync-cdr createOfflineSessions sessionsReturned !!sessionToCreate.answers.length', sessionsReturned.sessionToCreate.answers.length);
            // console.log('sync-cdr createOfflineSessions sessionsReturned !!sessionCreated.answers.length', sessionsReturned.sessionCreated.answers.length);
            if (!!sessionsReturned && !!sessionsReturned.sessionCreated) {
              sessions.push(sessionsReturned.sessionCreated);
              if (
                !!sessionsReturned.sessionToCreate && !!sessionsReturned.sessionToCreate.answers &&
                !!sessionsReturned.sessionCreated && !!sessionsReturned.sessionCreated.answers &&
                sessionsReturned.sessionCreated.answers.length === sessionsReturned.sessionToCreate.answers.length
              ) {
                // console.log('sync-cdr createOfflineSessions sessionsReturned add sessionsReturned.sessionToCreate', JSON.parse(JSON.stringify(sessionsReturned.sessionToCreate)));
                this.syncedOfflineSessions.push(sessionsReturned.sessionToCreate);
              }
            }
            if (sessionsToCreate.length === (count + 1)) {
              await this.studentService.removeGuestData();
              resolve(sessions);
            }
            count++;
          }
        );
      });
    }
    return new Promise(resolve => resolve([]));
  }

  async updateLastSessions(student: Student) {
    if (!!student) {
      const lSessions = await this.sessionsService.sessionsContext.getSessions(student, true);
      // console.log('sync-cdr updateLastSessions lSessions', JSON.parse(JSON.stringify(lSessions)));
      const lSeriesList = await this.seriesService.seriesContext.getSeriesList(student, true);
      // console.log('sync-cdr updateLastSessions lSeriesList', JSON.parse(JSON.stringify(lSeriesList)));
      if (!!lSessions && lSessions.length > 0) {
        for (const lSession of lSessions) {
          const index = (!!lSession.mode && lSession.mode === 'Cdr::ExamSession') ? 1 : 0;
          if (!!lSeriesList && !!lSession.serieId && !!lSession.id && !!lSession.lastAnsweredAt) {
            const serieFound = lSeriesList.find(elt => elt.id === lSession.serieId);
            // console.log('sync-cdr updateLastSessions serieFound', JSON.parse(JSON.stringify(serieFound)));
            if (!!serieFound && !!serieFound.id) {
              const serie = await this.seriesService.seriesContext.getSerie(serieFound.id, student, true, true);
              if (!!serie && !!serie.lastSessions) {
                let localSerieFound = null;
                if (serie.lastSessions[index] === null) {
                  serie.lastSessions[index] = lSession.id;
                  localSerieFound = lSeriesList.find(elt => elt.id === lSession.serieId);
                  if (!!localSerieFound && !!localSerieFound.lastSessions[index]) {
                    localSerieFound.lastSessions[index] = lSession.id;
                    const seriesListIndex = lSeriesList.findIndex(elt => elt.id === localSerieFound.id);
                    if (seriesListIndex !== 1) {
                      lSeriesList[seriesListIndex] = localSerieFound;
                    }
                  }
                } else {
                  const session = lSessions.find(elt => elt.id === serie.lastSessions[index]);
                  // DATE TO CHANGE
                  if (!!session && !!session.lastAnsweredAt && this.dateToFormatFr(lSession.lastAnsweredAt).valueOf() > this.dateToFormatFr(session.lastAnsweredAt).valueOf()) {
                    serie.lastSessions[index] = lSession.id;
                    localSerieFound = lSeriesList.find(elt => elt.id === lSession.serieId);
                    if (!!localSerieFound && !!localSerieFound.lastSessions[index]) {
                      localSerieFound.lastSessions[index] = lSession.id;
                      const seriesListIndex = lSeriesList.findIndex(elt => elt.id === localSerieFound.id);
                      if (seriesListIndex !== -1) {
                        lSeriesList[seriesListIndex] = localSerieFound;
                      }
                    }
                  }
                }
                await this.seriesService.seriesContext.setSerie(serie, student);
              }
            }
          }
        }
      }
      if (!!lSeriesList && lSeriesList.length > 0) {
        // console.log('sync-cdr updateLastSessions lSeriesList', JSON.parse(JSON.stringify(lSeriesList)));
        return await this.seriesService.seriesContext.setSeriesList(lSeriesList, student);
      }
      return [];
    }
    this.sentryService.sendToSentry('SyncCDR updateLastSessions: student null', this.studentService.student);
    return [];
  }

  handleOfflineAnswers(answers: Answer[], student: Student, sessionId: string): Promise<Answer[]> {
    if (!!student && !!answers && answers.length > 0 && !!sessionId) {
      return new Promise((resolve, reject) => {
        const answersSent = [];
        const answersToSend = answers.filter(elt => elt.id === 'offline');
        let count = 0;
        if (!!answersToSend && answersToSend.length > 0) {
          // console.log('sync-cdr handleOfflineAnswers answersToSend ', JSON.parse(JSON.stringify(answersToSend)));
          this.sendOfflineAnswers(answersToSend, student, sessionId).subscribe(
            async answerSent => {
              // console.log('sync-cdr handleOfflineAnswers answerSent', JSON.parse(JSON.stringify(answerSent)));
              if (!!answerSent) {
                answersSent.push(answerSent);
              }
              if (answersToSend.length === (count + 1)) {
                answersSent.concat(answers.filter(elt => elt.id !== 'offline'));
                resolve(answersSent);
              }
              count++;
            }
          );
        } else {
          resolve(answers);
        }
      });
    }
    return new Promise(resolve => resolve([]));
  }

  sendOfflineAnswers(answersToSend: Answer[], student: Student, sessionId: string): Observable<Answer> {
    // console.log('sendOfflineAnswers', student, JSON.parse(JSON.stringify(answersToSend)), JSON.parse(JSON.stringify(sessionId)));
    if (!!student && !!answersToSend && answersToSend.length > 0 && !!sessionId) {
      return from(answersToSend).pipe(
        mergeMap(
          async answerToSend => {
            answerToSend.sessionId = sessionId;
            // envoi une par une : pas top !
            return await this.answersService.post(answerToSend, student, 'sendOfflineAnswers');
          }
        )
      );
    }
    return of(null);
  }

  fetchOfflineSessions(sessionsToCreate: Session[], student: Student): Observable<any> {
    if (!!student && !!sessionsToCreate && sessionsToCreate.length > 0) {
      return from(sessionsToCreate).pipe(
        mergeMap(
          async sessionToCreate => {
            // console.log('sync-cdr fetchOfflineSessions sessionToCreate ', JSON.parse(JSON.stringify(sessionToCreate)));
            if (!!sessionToCreate && !!sessionToCreate.serieId && !!sessionToCreate.fakeId && sessionToCreate.fakeId === 'offline') {
              const serie = await this.seriesService.seriesContext.getSerie(sessionToCreate.serieId, this.studentService.guest, false);
              let mode: string = null;
              if (!!sessionToCreate.mode) {
                if (sessionToCreate.mode.includes('Exam')) {
                  mode = 'Exam';
                } else {
                  mode = 'Training';
                }
              }
              if (!!serie && !!mode) {
                return this.sessionsService.createSessionBySerie(serie, student, { mode: mode }).pipe(
                  switchMap(
                    async session => {
                      if (!!session) {
                        // console.log('sync-cdr fetchOfflineSessions createSessionBySerie session returned ', JSON.parse(JSON.stringify(session)));
                        session.answers = await this.handleOfflineAnswers(sessionToCreate.answers, student, session.id);
                      }
                      return {sessionToCreate: sessionToCreate, sessionCreated: session};
                    }
                  )
                ).toPromise();
              } else {
                return null;
              }
            } else {
              if (!!sessionToCreate && !!sessionToCreate.answers) {
                // console.log('sync-cdr fetchOfflineSessions sessionToCreate fakeId not offline or no serieId');
                sessionToCreate.answers = await this.handleOfflineAnswers(sessionToCreate.answers, student, sessionToCreate.id);
                return {sessionToCreate: sessionToCreate, sessionCreated: sessionToCreate};
              }
              return null;
            }
          }
        )
      );
    }
    return of(null);
  }

  async getSessionsToCreate(student: Student): Promise<Session[]> {
    if (!!student) {
      const guestSessions = (await this.sessionsService.sessionsContext.getSessions(this.studentService.guest, true)).filter(elt => elt.fakeId === 'offline' || elt.fakeId === 'update');
      const sessions = await this.sessionsService.sessionsContext.getSessions(student, true);
      if (!!sessions && sessions.length > 0 && !!guestSessions && guestSessions.length > 0) {
        return sessions.filter(elt => (elt.fakeId === 'offline' && !!elt.answers && !!elt.answersCount && elt.answers.length === elt.answersCount) || elt.fakeId === 'update').concat(guestSessions);
      }
      return sessions.filter(elt => (elt.fakeId === 'offline' && !!elt.answers && !!elt.answersCount && elt.answers.length === elt.answersCount) || elt.fakeId === 'update');
    }
    return new Promise(resolve => resolve([]));
  }

  async removeOfflineSessionsSynced(student: Student, sessionsToDelete: Session[]): Promise<boolean> {
    // console.log('sync-cdr removeOfflineSessionsSynced sessionsToDelete ', JSON.parse(JSON.stringify(sessionsToDelete)));
    if (!!student && !!sessionsToDelete && sessionsToDelete.length > 0) {
      const sessions = await this.sessionsService.sessionsContext.getSessions(student, true);
      // console.log('sync-cdr removeOfflineSessionsSynced sessions ', JSON.parse(JSON.stringify(sessions)));
      for (const session of sessionsToDelete) {
        if (session.fakeId === 'offline') {
          const indexSession = sessions.findIndex(elt => elt.id === session.id);
          if (indexSession !== -1) {
            sessions.splice(indexSession, 1);
          }
        }
      }
      const newSessions = await this.sessionsService.sessionsContext.setSessions(sessions, student);
      this.syncedOfflineSessions = []; // reset
      // console.log('sync-cdr removeOfflineSessionsSynced newSessions ', JSON.parse(JSON.stringify(newSessions)));
      return true;
    }
    return false;
  }
}
