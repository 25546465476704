import { ErrorCatcherService, SentryService } from '@app/app.services';
import { SoftDeployService } from './softDeploy.service';
import { Injectable } from '@angular/core';

import { Platform, ModalController } from '@ionic/angular';
import { NavController } from '@ionic/angular';

import { Initialable } from './app-init.service';
import { StorageService } from './storage.service';
import { StudentService } from './student.service';
import { ConfigUserService } from './config-user.service';
import { NetworkStatusService } from './network-status.service';
import { StatsService } from './stats.service';
import { UserErrorHandlerService } from './user-error-handler.service';

import { Student } from '../models/student';

import { NgxZendeskWebwidgetService } from '@nitsanzo/ngx-zendesk-webwidget';

import { UtilsService, ToasterService } from '@commons/services';
import { SiteOpener } from './site-opener.service';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init2', initializer: 'onInit' })
@EasyDebugDecorator
export class NavigationService {

  student: Student = null;
  configUser: any;
  userLifeCycle: any;
  universalLink = null;

  // PAGE LOAD EVENT
  timestamp = null;
  lastLink = null;

  featuresLinks = ['zendesk'];

  iframeAlreadyOpen = false;

  queryStringOrigin = 'spa';

  linksToUrl = {
    'cms': environment.cmsBase,
    'legacy': environment.legacyBase,
    'home': {path: '/', base: 'cms'},
    'avis-cms': {path: '/avis-envoituresimone', base: 'cms'},
    'offres-cms': {path: '/', base: 'cms'},
    'blog': {path: '/blog', base: 'cms'},
    'cgu': {path: '/cgu', base: 'cms'},
    'code-de-la-route': {path: '/code-de-la-route', base: 'cms'},
    'code-de-la-route-gratuit': {path: '/code-de-la-route-gratuit', base: 'cms'},
    'conduite-site': {path: '/conduite', base: 'legacy'},
    'dossier': {path: '/dossier', base: 'legacy'},
    'dossierConvocation': {path: '/dossier/convocation', base: 'legacy'},
    'livret': {path: '/dossier#info-section', base: 'legacy'},
    'livret-apprentissage': {path: '/livret/userId', base: 'legacy'},
    'examen-code-nouveau': {path: '/examen-code/nouveau', base: 'legacy'},
    'examen-code-examens': {path: '/examen-code/examens', base: 'legacy'},
    'evaluate': {path: '/lesson/lessonId/rating', base: 'legacy'},
    'infos': {path: '/informations-pratiques', base: 'legacy'},
    'acceptLearningContact': {path: '/evaluation-prealable/edit', base: 'legacy'},
    'lecons-conduite': {path: '/lecons-de-conduite', base: 'cms'},
    // 'lesson': {path: '/lesson/lessonId', base: 'legacy'},
    // 'lessons': {path: '/lessons', base: 'legacy'},
    // 'reserver-lecon': {path: '/lesson/nouvelle', base: 'legacy'},
    'tunnel': {path: '/tunnel', base: 'cms'},
    'tunnelInscription': {path: '/tunnel/bienvenue', base: 'cms'},
    'tunnelOffer': {path: '/tunnel?offer_id=offerId', base: 'legacy'},
    'tunnelPaiement': {path: '/tunnel/paiement?offer_id=offerId&quantity=offerQuantity', base: 'legacy'},
    'cpf': {path: '/demarches-et-informations/cpf-permis', base: 'cms'},
    'infos-cpf': {path: '/demarches-et-informations/cpf-permis', base: 'cms'},
    'faq': {path: 'https://aide.envoituresimone.com/hc/fr', base: ''},
    'our-cars': {path: 'https://voitures.envoituresimone.com', base: ''},
    'supportNewRequest': {path: 'https://aide.envoituresimone.com/hc/fr/requests/new', base: ''},
    'insExamen': {path: '/inscription-examen-code', base: 'cms'},
    'insExamenOld': {path: 'https://aide.envoituresimone.com/hc/fr/articles/360000803069-Comment-s-inscrire-%C3%A0-l-examen-du-code-', base: ''},
    'aideExamen': {path: 'https://aide.envoituresimone.com/hc/fr/articles/360010451680-Comment-se-d%C3%A9roule-l-examen-du-code-', base: ''},
    'aideExamenConduite': {path: '/s-inscrire-a-l-examen-pratique', base: 'cms'},
    'demarchesAdministratives': {path: 'https://aide.envoituresimone.com/hc/fr/categories/360000107269-Mes-d%C3%A9marches-administratives', base: ''},
    'demarchesPrefecture': {path: 'https://aide.envoituresimone.com/hc/fr/articles/360000786625', base: ''},
    'questionsExamen': {path: '/permis-de-conduire/examen-permis-conduire/questions-permis', base: 'cms'},
    'aideDocuments' : {path: 'https://aide.envoituresimone.com/hc/fr/articles/360000760005-Quels-sont-les-documents-%C3%A0-apporter-le-jour-de-l-%C3%A9preuve-du-permis-de-conduire-', base: ''},
    'aideSuppressionCompte' : {path: 'https://aide.envoituresimone.com/hc/fr/articles/360000760249', base: ''},
    'aideNEPH' : {path: 'https://aide.envoituresimone.com/hc/fr/articles/360000760245-Qu-est-ce-que-le-NEPH-', base: ''},
    'howNEPH' : {path: 'https://aide.envoituresimone.com/hc/fr/articles/360000786625-Comment-faire-ma-demande-de-NEPH', base: ''},
    'gouvNEPH' : {path: 'https://permisdeconduire.ants.gouv.fr/demarches-en-ligne/inscription-examen-permis', base: ''},
    'lostNEPH' : {path: 'https://aide.envoituresimone.com/hc/fr/articles/360000822845-J-ai-perdu-ou-je-ne-connais-pas-mon-NEPH-Mon-NEPH-est-inactif', base: ''},
    'livretApprentissageAide': {path: '/permis-de-conduire/conseils-conduite/livret-d-apprentissage', base: 'cms'},
    'paiement': {path: '/boutique/paiement/paiementId', base: 'legacy'},
    'paiementCodeExamReservation': {path: '/boutique/paiement/offerId?device_and_path=deviceAndPath', base: 'legacy'},
    'paiement1hour': {path: '/boutique/paiement/paiementId?quantity=1', base: 'legacy'},
    'examRegalurization': {path: '/boutique/exam_regularization', base: 'legacy'},
    'paiements': {path: '/boutique/paiements', base: 'legacy'},
    // 'parrainage': {path: '/parrainage/redirect?user_id=userId', base: 'legacy'},
    'permis': {path: '/permis-de-conduire', base: 'cms'},
    'permisEnDeuxMois': {path: '/permis-de-conduire/permis-en-2-mois', base: 'cms'},
    'examDateRDVP': {path: '/permis-de-conduire/date-examen-permis-avec-rdvpermis', base: 'cms'},
    'app': {path: '/app', base: 'cms'},
    'points-rdv': {path: '/points-de-rendez-vous', base: 'cms'},
    'profile': {path: '/dossier/informations-personnelles', base: 'legacy'},
    'regulariserPayment': {path: '/boutique/impayé/ppu/résoudre', base: 'legacy'},
    'signOut': {path: '/user/delete_session', base: 'cms'},
    'tarifs': {path: '/tarifs', base: 'cms'},
    'penurie': {path: '/reserver-une-lecon-avec-en-voiture-simone', base: 'cms'},
    'politiqueConfidentialite': {path: '/politique-confidentialite', base: 'cms'},
    'drivingExamConvocationForm': {path: '/rdvp/evs-driving-exam-convocation-form.pdf', base: 'legacy'},
    'identityJustificatives': {path: '/rdvp/evs-driving-exam-identity-documents-BER-05-2024.pdf', base: 'legacy'},
    'aideProfil' : {path: 'https://aide.envoituresimone.com/hc/fr', base: ''},
    'myTrainingAccount' : {path: 'https://www.moncompteformation.gouv.fr/espace-prive', base: ''},
    'nephReject' : {path: 'https://aide.envoituresimone.com/hc/fr/articles/16884861223058-Gestion-de-votre-num%C3%A9ro-NEPH-pr%C3%A9sent-dans-votre-attestation-d-inscription', base: ''},
    // GOOGLE
    'programmeFormation': {path: 'https://drive.google.com/file/d/1fUEk0dhCQ8ixu5KonGsCMZL2NR93_sxW/view ', base: ''},
    'google-map': {path: 'https://www.google.com/maps/place/term', base: ''},
    // TYPEFORMS
    'sadtypeform': {path: 'https://en-voiture-simone.typeform.com/to/hwsRjW', base: ''},
    'happytypeform': {path: 'https://en-voiture-simone.typeform.com/to/K0AjFu', base: ''},
    'exp5Typeform': {path: 'https://en-voiture-simone.typeform.com/to/xTzOJ6', base: ''},
    'exp20Typeform': {path: 'https://en-voiture-simone.typeform.com/to/hftVWg', base: ''},
    'situationTypefom': {path: 'https://en-voiture-simone.typeform.com/to/tsi3gz?uid=userId', base: ''},
    // TUNNELS
    'cgv': {path: 'https://www.envoituresimone.com/sales_terms/show', base: ''},
    'privacy_policy': {path: 'https://www.envoituresimone.com/politique-confidentialite', base: ''},
    // NOTIONS
    'examenVideo': {path: 'https://www.notion.so/Vid-os-centres-d-examen-5aa4703ed7174af28c7d33fcd49e12e5', base: ''},
    'eLearning': {path: 'https://enchanting-rain-725.notion.site/Mon-espace-e-learning-En-Voiture-Simone-b164fbeafa4749e0b54ad7fc6bf30565', base: ''},
    'drivingExamWaitingTime': {path: 'https://enchanting-rain-725.notion.site/L-tat-faillit-ses-promesses-des-d-lais-inacceptables-pour-le-permis-de-conduire-57f8eb4ad40d4716b1f0be42b9988598', base: ''},
    // CODE ENGINE
    'questionsVerifications': {path: 'https://code-enligne.fr/doc/questions-verifications-2018-banque-verifications-01_01_18-2.pdf', base: ''},
    // LEGIFRANCE
    'legifranceArrete': {path: 'https://www.legifrance.gouv.fr/loda/id/JORFTEXT000033736411', base: ''},
    // BLOGS
    'blogInsExam': {path: '/blog/tuto-sinscrire-a-lexamen-du-code-de-la-route', base: 'legacy'},
    'blogConseilCode': {path: '/blog/nos-13-conseils-pour-reussir-son-code-de-la-route-du-premier-coup', base: 'legacy'},
    'blogVitesseMeteo': {path: '/blog/vitesse-et-meteo-que-dit-le-code-de-la-route', base: 'legacy'},
    'blogFautesPermis': {path: '/blog/permis-de-conduire-ces-fautes-eliminatoires-a-avoir-en-tete', base: 'legacy'},
    'blog3Conseils': {path: '/permis-de-conduire/examen-permis-conduire/stress-permis', base: 'cms'},
    'promodeconf': {path: '/blog/code-promo-deconfinement-100eur-offert-sur-le-pack-permis', base: 'legacy'},
    'promosummer2020': {path: '/blog/code-promo-soldes-dete-50eur-offert-sur-le-pack-permis', base: 'legacy'},
    // CGAAC (dynamic url)
    'cgaac': {path: 'https://evs-profilepics.s3.amazonaws.com/cgaacUrl', base: ''},
    'convocationDriving': {path: 'convocationUrl', base: ''},
    'insuranceFile': {path: 'insuranceFileUrl', base: ''},
    'cerfaFile': {path: 'cerfaFileUrl', base: ''},
    'youtubeTiktok': {path: 'https://www.youtube.com/playlist?list=PLrjycpUFXbQ4TA7XSOScS1up6po5gxxL6', base: ''},
    'tiktok': {path: 'https://www.tiktok.com/@envoituresimone_fr', base: ''},
  };

  frozenWhitelistRoutes = [
    'dashboard',
    'code',
    'profil',
    'blog',
    'cgu',
    'cgaac',
    'documents',
    'dossier',
    'infos',
    'faq',
    'supportNewRequest',
    'aideExamen',
    'demarchesAdministratives',
    'paiement',
    'regulariserPayment',
    'signOut',
    'blogInsExam',
    'blogConseilCode',
    'blogVitesseMeteo',
    'blogFautesPermis',
    'blog3Conseils',
  ];

  offline_lastLink: any;

  isCordova = false;

  urlToRedirectForIos = [
    'blog',
    'cgu',
    'infos',
    'tunnel',
    'tunnelOffer',
    'paiement',
    'permis',
    'regulariserPayment',
    'tarifs',
    'cgaac',
    'tunnelInscription',
  ];

  forceNoIframe = false;
  closedIframeObs: Subject<any> = new Subject<any>();
  closedIframeObs$ = this.closedIframeObs.asObservable();

  userNavigation = [];

  whiteList: string[] = [
    'aide.envoituresimone',
    'typeform',
    'amazonaws',
    'enseignant.envoituresimone.com',
    'drive.google',
    'google',
    'notion.so',
    'legifrance.gouv.fr',
    'code-enligne',
    'youtube',
    'tiktok'
  ];
  initLaunched = false;

  constructor(
    public navController: NavController,
    private utils: UtilsService,
    public router: Router,
    private platformService: Platform,
    private storageService: StorageService,
    private studentService: StudentService,
    private configUserService: ConfigUserService,
    private networkStatusService: NetworkStatusService,
    private zendeskService: NgxZendeskWebwidgetService,
    public siteOpener: SiteOpener,
    private statsService: StatsService,
    public toasterService: ToasterService,
    private appConfig: ConfigService,
    private platform: Platform,
    private modalController: ModalController,
    private softDeployService: SoftDeployService,
    private market: Market,
    private sentryService: SentryService,
    private errorCatcherService: ErrorCatcherService,
    private userErrorHandlerService: UserErrorHandlerService,
  ) {
    this.isCordova = this.platformService.is('cordova');
    this.iframeAlreadyOpen = false;
  }

  async onInit() {
    return new Promise(
      (resolve, reject) => {
        this.siteOpener.linkList = this.linksToUrl;
        this.siteOpener.whiteList = this.whiteList;
        this.iframeAlreadyOpen = false;
        resolve('Navigation done');
      }
    );
  }

  async open(link: string, options?: any) {
    console.log('navigationService open', link, options);

    // tunnel redirect
    if (link === 'permis') {
      link = 'tunnel-paiement';
    }

    this.initLaunched = false;
    if (typeof link !== 'undefined') {
      if (link === 'reload' && this.platform.is('cordova')) {
        this.softDeployService.reloadApp();
        return;
      }
      this.student = this.studentService.student;
      this.configUser = await this.configUserService.getUserConfig(this.student);
      this.userLifeCycle = this.studentService.getUserLifeCycle(this.student);

      const currentRoute = this.router.url.substr(1);
      // if (link === 'offres' && (this.userLifeCycle.isUserGuest || this.userLifeCycle.isUserRegistered) && currentRoute !== 'conduite') {
      //   link = 'offres-cms';
      // }

      let forceInAppBrowser =  false;
      if (!!options && !!options.forceOutLink && options.forceOutLink) {
        forceInAppBrowser =  true;
      }

      let sendToIframe = false;
      if (this.isCordova && !this.forceNoIframe) {
        sendToIframe = true;
      }
      // if (this.isCordova && this.platformService.is('ios')) {
      //   if (this.urlToRedirectForIos.includes(link)) {
      //     link = 'acceder-au-site';
      //   }
      // }
      // ######################################################
      // # DEBUG FOR DESKTOP TESTS - DO NOT PUT IN PRODUCTION #
      // ######################################################
      // sendToIframe = true;
      // ######################################################
      // # DEBUG FOR DESKTOP TESTS - DO NOT PUT IN PRODUCTION #
      // ######################################################
      if (this.featuresLinks.includes(link)) {
        if (link === 'zendesk') {
          this.timestamp = null;
          this.lastLink = null;
          // this.userNavigation.push({
          //   link: 'zendesk',
          //   target: 'app'
          // });
          this.statsService.send({ name: 'page:view', payload: { page: '/zendesk' } });

          const currentRoute = this.router.url.substr(1);
          if (currentRoute.includes('series/') && !!options && !options.questionCodeTag) {
            // this.sentryService.sendToSentry(`DebugZendeskQuestionCode URL => ${JSON.stringify(currentRoute)} - OPTIONS => ${JSON.stringify(options)}`, this.studentService.student);
          }
          this.activateZendesk(options);
        }
        if (link === 'rating') {
          this.timestamp = null;
          this.lastLink = null;
          this.statsService.send({ name: 'page:view', payload: { page: '/rating' } });
          this.activateRating();
          this.userNavigation.push({
            link: 'rating',
            target: 'app'
          });
        }
      } else {
        if (this.userLifeCycle.isFrozen && currentRoute !== 'gmap') {
          if (!this.frozenWhitelistRoutes.includes(link)) {
            if (!!this.student.subStatus && this.student.subStatus.includes('payment_failed')) {
              link = 'impayes';
            }
          }
        }
        if ((typeof this.linksToUrl[link] === 'undefined' || (!!this.linksToUrl[link] && !!this.linksToUrl[link].path && typeof this.linksToUrl[link].path === 'undefined')) && !forceInAppBrowser) {
          if (!!options && !!options.forcedSiteOpener && options.forcedSiteOpener) {
            if (this.networkStatusService?.isOffline()) {
              this.saveLinkForSiteOpener(link, options);
            } else {
              if (!!options && !!options.target && options.target === '_system') {
                sendToIframe = false;
              }
              if (this.isCordova && this.platform.is('ios')) {
                sendToIframe = false;
              }
              if (sendToIframe) {
                // console.log(link);
                if (!this.isCordova && link === 'signOut' && this.iframeAlreadyOpen) {
                  this.iframeAlreadyOpen = false;
                }
                if (!this.iframeAlreadyOpen) {
                  // this.userNavigation.push({
                  //   link: link,
                  //   target: 'iframe'
                  // });
                  this.iframeAlreadyOpen = true;
                  // console.log('student => ', this.student)
                  const securedUrl = await this.siteOpener.getSecuredUrl(link, this.student, options);
                  if (!!securedUrl && !!securedUrl.errorMessage) {
                    this.userErrorHandlerService.addError({criticity: 10, service: 'getSecuredUrl', platform: 'both', data: securedUrl, errorCode: 'nsgsu'});
                    // this.sentryService.sendToSentry(`EVSERROR Navigation Service Open securedUrl ${JSON.stringify(securedUrl)}`, this.studentService.student);
                    return null;
                  }
                  // console.log('SECURED URL => ', securedUrl);
                  if (!securedUrl) {
                    this.iframeAlreadyOpen = false;
                  }
                  return securedUrl;
                } else {
                  return null;
                }
              } else {
                // console.log('link 2 => ', link);
                this.siteOpenerOpen(link, options);
              }
            }
          }
          this.timestamp = Date.now();
          this.lastLink = link;
          // this.statsService.send({ name: 'page:view', payload: { page: link } });
          // console.log(link, JSON.stringify(options));
          this.navigationControllerOpen(link, options);
        } else {
          // si url part of external domain then force target system
          if (!!this.linksToUrl[link] && !!this.linksToUrl[link].path && this.siteOpener.checkWhiteList(this.linksToUrl[link].path)) {
            if (!!options) {
              options.target = '_system';
            } else {
              options = {target: '_system'};
            }
          }
          if (this.networkStatusService?.isOffline()) {
            this.saveLinkForSiteOpener(link, options);
          } else {
            if (!!options && !!options.target && options.target === '_system') {
              sendToIframe = false;
            }
            if (this.isCordova && this.platform.is('ios')) {
              sendToIframe = false;
            }
            if (sendToIframe) {
              if (!this.iframeAlreadyOpen) {
                this.iframeAlreadyOpen = true;
                await this.resetStickyToasters();
                // this.userNavigation.push({
                //   link: link,
                //   target: 'iframe'
                // });
                // console.log('student => ', this.student)
                const securedUrl = await this.siteOpener.getSecuredUrl(link, this.student, options);
                if (!!securedUrl && !!securedUrl.errorMessage) {
                  this.userErrorHandlerService.addError({criticity: 10, service: 'getSecuredUrl', platform: 'both', data: securedUrl, errorCode: 'nsgsu'});
                  // this.sentryService.sendToSentry(`EVSERROR Navigation Service Open securedUrl ${JSON.stringify(securedUrl)}`, this.studentService.student);
                  return null;
                }
                if (!securedUrl) {
                  this.iframeAlreadyOpen = false;
                }
                return securedUrl;
              } else {
                return null;
              }
            } else {
              // console.log('link 1 => ', link);
              this.siteOpenerOpen(link, options);
            }
          }
        }
      }
    } else {
      console.error(JSON.stringify('NavigationService: No link!'));
    }
  }

  async navigationControllerOpen(link: string, options?: any) {
    let allow = true;
    if (
      this.userNavigation.length > 0 &&
      !!this.userNavigation[this.userNavigation.length - 1].link &&
      this.userNavigation[this.userNavigation.length - 1].link === link
    ) {
      allow = false;
    }
    if (allow) {
      this.userNavigation.push({
        link: link,
        target: 'app'
      });
    }
    // console.log('navigationControllerOpen');
    let forceRoot = false;
    if (!!options) {
      if (!!options.forceRoot && options.forceRoot) {
        forceRoot = true;
      }
    }

    forceRoot = true; // temp but too much case fucked with navigateForward
    if (typeof link === 'string' && link !== '') {
      if (forceRoot) {
        this.navController.navigateRoot(link);
      } else {
        this.navController.navigateForward(link);
      }
    } else {
      this.navController.back();
    }
  }


  async siteOpenerOpen(link: string, options?: any) {
    // this.userNavigation.push({
    //   link: link,
    //   target: 'siteOpener'
    // });
    // console.log('options => ', options);
    if (!options) {
      // _blank: Opens in the InAppBrowser. (default for evs)
      // _system: Opens in the system's web browser.
      options = { target: '_blank' };
    }
    // Is that the right place?
    // If we want to count InAppView like an app view and not a web view, we have to count it here...
    // What about internal Navigation?
    this.timestamp = null;
    this.lastLink = null;

    let redirectLink = 'undefined';
    if (!!this.linksToUrl[link]) {
      let base = '';
      if (!!this.linksToUrl[link].base) {
        const baseKey = this.linksToUrl[link].base;
        base = this.linksToUrl[baseKey];
      }
      if (!!this.linksToUrl[link].path) {
        redirectLink = base + this.linksToUrl[link].path;
      }
    }
    this.statsService.send({ name: 'page:redirect', payload: { pageRedirect: redirectLink } });
    this.siteOpener.open(link, this.student, options).then(
      res => {
        if (!!res && !!res.errorMessage) {
          if (this.errorCatcherService.getRow('ME003').message !== res.errorMessage) {
            this.userErrorHandlerService.addError({criticity: 10, service: 'siteOpener', platform: 'both', data: res, errorCode: 'nsso'});
            // this.sentryService.sendToSentry('NavigationService siteOpener: ' + JSON.stringify(res), this.studentService.student);
          }
        }
      }
    ).catch(
      (err) => {
        this.userErrorHandlerService.addError({criticity: 10, service: 'siteOpener', platform: 'both', data: err, errorCode: 'nsso'});
        // this.sentryService.sendToSentry('NavigationService siteOpener: ' + JSON.stringify(err), this.studentService.student);
      }
    );
    let autologLaunched = false;
    this.siteOpener.return.subscribe(
      async res => {
        if (!!res && res.error && !!res.additionalData && !!res.additionalData.code && (res.additionalData.code === -2 || res.additionalData.code === -1009)) {
          this.saveLinkForSiteOpener(link, options);
        }
        if (!!res && !!res.end && res.end.length > 0) {
          if (res.end[0] === 'boutique' && res.end[1] === 'paiement') {
            // reload paiement
          }
        }
        if (!!res && !!res.url && res.url.indexOf('terminer-mon-inscription') > -1) {
          this.open('terminer-mon-inscription');
          return;
        }
        if (!!res && !!res.url && res.url.indexOf('reservation-examen-code') > -1) {
          this.open('reservation-examen-code');
          return;
        }
        if (!!res && !!res.url && res.url.indexOf('mon-inscription-examen') > -1) {
          this.open('mon-inscription-examen');
          return;
        }
        if (!!res && !!res.url && !autologLaunched) {
          autologLaunched = true;
          await this.studentService.autoLogPromise(res.url);
        }
      }
    );
  }

  async resetStickyToasters() {
    const stickyToasters = document.getElementsByClassName('evs_toaster_sticky');
    for (const el of <any>stickyToasters) {
      el.parentNode.removeChild(el);
    }
    if (!this.configUser || this.configUser == null) {
      this.configUser = await this.configUserService.getUserConfig(this.student);
    }
    if (!!this.configUser) {
      this.configUser.impayesStickyToasterShown = false;
    }
    await this.configUserService.setUserConfig(this.student, this.configUser);
  }

  activateZendesk(options?: any) {
    const tags = [];

    if (!!options) {
      if (!!options.questionCodeTag) {
        tags.push('question_code');
        if (!!options.questionId) {
          tags.push(options.questionId);
        }
      }
    }

    if (this.zendeskService.isInitialized) {
      this.configUserService.updateZendeskTags(tags);
      this.zendeskService.zE('webWidget', 'show');
      this.zendeskService.zE('webWidget', 'open');
      this.zendeskService.zE('webWidget:on', 'close', () => {
        this.zendeskService.zE('webWidget', 'hide');
      });
    } else {
      alert('Erreur: le contact avec le support n\'est pas disponible pour le moment.');
    }
  }

  activateRating() {
    // console.log('RATING TODO');
  }

  // used when siteOpener try to access to a webpage without internet connection
  async saveLinkForSiteOpener(link: string, options?: any) {
    // console.log('saveLinkForSiteOpener', link);
    const student = this.studentService.student;
    const url = this.router.url.replace(/^\//, '');
    const offlineObj = {
      type: 'siteopener',
      from: url,
      link: link,
      options: options || null,
    };
    await this.storageService.set(`${student.remoteId}-OfflineLastLink`, offlineObj);
    this.navigationControllerOpen('disconnected', options);
  }

  // used when serie or part of the app can't reach data because no internet connection
  async saveLinkToReload(options?: any) {
    // console.log('saveLinkToReload');
    const student = this.studentService.student;
    const url = this.router.url.replace(/^\//, '');
    let offlineObj = {
      type: 'app',
      link: url,
    };
    if (!!options && !!options.modalToOpenOnReload) {
      offlineObj['modalToOpenOnReload'] = options.modalToOpenOnReload;
    }
    await this.storageService.set(`${student.remoteId}-OfflineLastLink`, offlineObj);
    if (this.networkStatusService?.isOffline()) {
      this.navigationControllerOpen('disconnected');
    }
  }

  async getLastNav() {
    // console.log('getLastNav');
    const student = await this.studentService.student;
    await this.storageService.get(`${student.remoteId}-OfflineLastLink`).then(
      link => {
        if (!!link) {
          this.offline_lastLink = link;
        }
      }
    );
  }

  checkUserNavigation() {
    let backUrl = null;
    // on filtre les urls de tableau sur l'url courante
    const filteredNav = this.userNavigation.filter(
      (elt) => elt.link === this.router.url.substr(1) && elt.target === 'app'
    );
    // on récupère l'index de la dernière occurence dans le tableau de l'url courante
    const indexInArray = this.userNavigation.findIndex((elt) => elt === filteredNav[filteredNav.length - 1]);
    if (
      !!this.userNavigation &&
      !!this.userNavigation[indexInArray - 1] &&
      !!this.userNavigation[indexInArray - 1].link &&
      filteredNav.length > 0 && indexInArray !== -1
    ) {
      backUrl = {
        url: this.userNavigation[indexInArray - 1].link,
      };
    }
    // console.log('this.userNavigation', this.userNavigation);
    // console.log('this.router.url.substr(1)', this.router.url.substr(1));
    // console.log('indexInArray', this.userNavigation.findIndex((elt) => elt === filteredNav[filteredNav.length - 1]));
    // console.log('backUrl', backUrl);
    return backUrl;
  }

  async reloadLink() {
    // console.log('reload');
    await this.getLastNav();
    if (!!this.offline_lastLink && !!this.offline_lastLink.type) {
      if (this.offline_lastLink.type === 'app') {
        // console.log(this.offline_lastLink);
        this.open(this.offline_lastLink.link);
      }
    } else {
      if (this.networkStatusService?.isOffline()) {
        this.open('disconnected');
      } else {
        if (!!this.offline_lastLink && !!this.offline_lastLink.link && this.offline_lastLink.link !== '' && this.offline_lastLink.options) {
          this.open(this.offline_lastLink.link, this.offline_lastLink.options);
        } else {
          this.open('dashboard');
        }
      }
    }
  }

}
