import { StorageService } from './storage.service';
import { QuestionsContext } from '../Strategy/questionsStrategy/questionsContext';
import { environment } from './../../environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Observable, of, from } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import { NetworkStatusService } from './network-status.service';
import { Initialable } from './app-init.service';
import { SessionsService } from './sessions.service';
import { StudentService } from './student.service';
import { SeriesService } from './series.service';

import { ToasterService } from '@commons/services';

import { Answer } from './../models/answer';
import { Student } from './../models/student';
import { Question } from '../models/question';
import { Session } from '../models/session';
import { Serie } from '../models/serie';
import { QuestionsService } from './questions.service';
import { LocalQuestions } from '../Strategy/questionsStrategy/localQuestions';
import { RemoteQuestions } from '../Strategy/questionsStrategy/remoteQuestions';
import { ServiceLocator } from './serviceLocator.service';
import { Platform } from '@ionic/angular';
import * as moment from 'moment-timezone';

const MIN_GOOD_ANSWERS = environment.minGoodAnswers;

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { SentryService } from '@app/app.services';

@Injectable({
  providedIn: 'root'
})
@Initialable({step: `init4`, initializer: `init`})
@EasyDebugDecorator
export class AnswersService {

  currentRoute = null;

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private studentService: StudentService,
    private seriesService: SeriesService,
    private sentryService: SentryService,
    private sessionsService: SessionsService,
    private errorHandlerService: ErrorHandler,
    private storageService: StorageService,
    private toasterService: ToasterService,
    private questionsService: QuestionsService,
    private platform: Platform,
    private router: Router,
  ) {
    // console.log('Answers service instanciate');
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.currentRoute = this.router.url.substr(1);
      }
    });
  }

  async init() {
    return 'Answers done';
  }

  downcaseAnswers(answers: string[]): string[] {
    const downcaseAnswers: string[] = [];
    if (!!answers && answers.length > 0) {
      for (const answer of answers) {
        downcaseAnswers.push(answer.toLowerCase());
      }
    }
    return downcaseAnswers;
  }


  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  async post(answer: Answer, student: Student, source: string): Promise<Answer> {
    // console.log('POST Fn', JSON.parse(JSON.stringify(answer)));
    if (!!student && !!student.remoteId && !!answer) {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${student.remoteId}/answers/`;
      // DATE TO CHANGE
      const date = (!!answer.answer && !!answer.updatedAt) ? this.dateToFormatFr(answer.updatedAt).toISOString() : this.dateToFormatFr().toISOString();
      const data = {
        question_id: (!!answer.questionId) ? answer.questionId : null,
        serie_id: (!!answer.serieId) ? answer.serieId : null,
        created_at: date,
        answer: (!!answer.answer) ? this.downcaseAnswers(answer.answer) : null,
        session_id: (!!answer.sessionId) ? answer.sessionId : null,
        timespent: (!!answer.timespent) ? answer.timespent : null
      };

      let sessions = [];
      const resGetSessions = await this.sessionsService.sessionsContext.getSessions(student);
      if (!!resGetSessions && !!resGetSessions.errorCode && resGetSessions.errorCode === 'E301') {
        // this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSessions', platform: 'both', data: resgetSessions.errorOriginal, errorCode: 'ssfss'});
      } else {
        sessions = resGetSessions;
      }
      // console.log('AnswerService -> post -> sessions for answer.sessionsId = ' + answer.sessionId, JSON.parse(JSON.stringify(sessions)));
      if (sessions.length === 0) {
        return null;
      }
      let session: Session = null;
      if (sessions.length > 0 && !!answer.sessionId) {
        session = sessions.find(elt => elt.id === answer.sessionId);
        if (!session) {
          return null;
        }
      }

      // console.log('AnswerService -> post -> session', JSON.parse(JSON.stringify(session)));

      if (
        (!!student.status && student.status !== 'guest') && !this.networkService?.isOffline() &&
        (!!session && ((!!session.fakeId && session.fakeId !== 'offline') || session.fakeId === null))
      ) {
        // console.log('AnswerService -> post ' + url, JSON.parse(JSON.stringify(data)));
        return this.http.post(url, data).pipe(
          switchMap(
            async (res: any) => {
              const id = (!!res && !!res.data && !!res.data.id) ? res.data.id : null;
              const attributes = (!!res && !!res.data && !!res.data.attributes) ? res.data.attributes : null;
              if (!!id && !!attributes) {
                const newAnswer = new Answer({
                  id: id,
                  answer: (!!attributes.answer) ? attributes.answer : null,
                  questionId: (!!attributes.question_id) ? attributes.question_id : null,
                  serieId: (!!attributes.serie_id) ? attributes.serie_id : null,
                  sessionId: (!!attributes.session_id) ? attributes.session_id : null,
                  state: (!!attributes.state) ? attributes.state : null,
                  timespent: (!!attributes.timespent) ? attributes.timespent : null,
                  updatedAt: (!!attributes.updated_at) ? attributes.updated_at : null,
                });

                const errorSession = await this.sessionsService.sessionsContext.getErrorSession(student);
                if (!!errorSession && !!errorSession.serieId) {
                  let serie: any;
                  let question = (!!answer && !!answer.questionId) ? await this.questionsService.questionsContext.getQuestion(answer.questionId, student) : null;
                  question = (!!question && !!question.id) ? question : null;
                  if (!!this.seriesService.errorSerie) {
                    serie = this.seriesService.errorSerie;
                  } else {
                    this.seriesService.errorSerie = await this.seriesService.seriesContext.getSerie(errorSession.serieId, student, true);
                    serie = this.seriesService.errorSerie;
                  }
                  if (!!question && serie.questions.indexOf(question.id) >= 0) {
                    serie.questions.splice(serie.questions.indexOf(question.id), 1);
                    serie.nbQuestions--;
                  }
                  if (!!answer.state && answer.state === 'false' && !!question) {
                    serie.questions.push(question.id);
                    serie.nbQuestions++;
                  }
                  if (this.platform.is('cordova')) {
                    await this.seriesService.seriesContext.setSerie(serie, student);
                  } else {
                    this.seriesService.errorSerie = serie;
                  }
                }
                return newAnswer;
              }
              return null;
            }
          ),
          catchError(
            err => {
              // 406 = déjà répondu
              if (environment.env === 'production' && err.status !== 406 && err.status !== 0) {
                this.errorHandlerService.handleError(new Error(JSON.stringify(err)));
              }
              if (err.status === 404) {
                this.sentryService.sendToSentry('Error 404: Post Answer in AnswerService while posting answer with session not existing in remote db', this.studentService.student);
                return of(new Answer({id: 'delSession', answer: null, questionId: null, serieId: null, sessionId: null, state: null, timespent: null, updatedAt: null}));
              }
              if (err.status === 406 && !!err.error && !!err.error.data && err.error.data.id && !!err.error.data.attributes) {
                const id = err.error.data.id;
                const attributes = err.error.data.attributes;
                const newAnswer = new Answer({
                  id: id,
                  answer: (!!attributes.answer) ? attributes.answer : null,
                  questionId: (!!attributes.question_id) ? attributes.question_id : null,
                  serieId: (!!attributes.serie_id) ? attributes.serie_id : null,
                  sessionId: (!!attributes.session_id) ? attributes.session_id : null,
                  state: (!!attributes.state) ? attributes.state : null,
                  timespent: (!!attributes.timespent) ? attributes.timespent : null,
                  updatedAt: (!!attributes.updated_at) ? attributes.updated_at : null,
                });
                if (!!this.currentRoute && this.currentRoute !== 'splash') {
                  this.toasterService.create({ text: 'Tu as déjà répondu à cette question !', bgcolor: 'var(--color-danger)', color: 'white' });
                  // this.sentryService.sendToSentry(`Toaster VADRACQ ${source}`, this.studentService.student);
                } else {
                  this.sentryService.sendToSentry(`Answer VADRACQ ${source}`, this.studentService.student);
                }
                return of(newAnswer);
              }
              return this.createFakeAnswer(answer, 'error', student);
            }
          )
        ).toPromise();
      }
      return this.createFakeAnswer(answer, 'offline', student);
    }
    this.sentryService.sendToSentry('AnswerService POST : No student', this.studentService.student);
    return null;
  }

  getAnswerState(question: Question, answers: string[]): string {
    if (!!question && !!answers && answers.length > 0) {
      if (answers.length === question.correctAnswers.length) {
        for (const answer of answers) {
          if (!question.correctAnswers.includes(answer)) {
            return 'false';
          }
        }
        return 'right';
      }
    }
    return 'false';
  }

  async createFakeAnswer(answer: Answer, id: string, student: Student): Promise<Answer> {
    if (!!answer && !!answer.sessionId && !!id && !!student) {
      let sessions = [];
      const resGetSessions = await this.sessionsService.sessionsContext.getSessions(student);
      if (!!resGetSessions && !!resGetSessions.errorCode && resGetSessions.errorCode === 'E301') {
        // this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSessions', platform: 'both', data: resgetSessions.errorOriginal, errorCode: 'ssfss'});
      } else {
        sessions = resGetSessions;
      }
      const session = sessions.find(elt => elt.id === answer.sessionId);
      answer.id = id;
      if (!!session && session.fakeId === null) {
        sessions.find(elt => elt.id === answer.sessionId).fakeId = 'update';
        await this.sessionsService.sessionsContext.setSessions(sessions, student);
      }

      const errorSession = await this.sessionsService.sessionsContext.getErrorSession(student);
      let question = null;
      const resGetQuestion = await this.questionsService.questionsContext.getQuestion(answer.questionId, student);
      if (!!resGetQuestion && !!resGetQuestion.errorCode && resGetQuestion.errorCode === 'E301') {
        // service failed
      } else {
        question = resGetQuestion;
      }
      if (!!errorSession && !!errorSession.serieId && !!question && !!question.id) {
        let serie;
        if (!!this.seriesService.errorSerie) {
          serie = this.seriesService.errorSerie;
        } else {
          this.seriesService.errorSerie = await this.seriesService.seriesContext.getSerie(errorSession.serieId, student, true);
          serie = this.seriesService.errorSerie;
        }
        if (!!serie.questions && serie.questions.indexOf(question.id) >= 0) {
          serie.questions.splice(serie.questions.indexOf(question.id), 1);
          serie.nbQuestions--;
        }
        if (!!answer.state && answer.state === 'false') {
          serie.questions.push(question.id);
          serie.nbQuestions++;
        }
        if (this.platform.is('cordova')) {
          await this.seriesService.seriesContext.setSerie(serie, student);
        } else {
          this.seriesService.errorSerie = serie;
        }
      }
      return answer;
    }
    return null;
  }

  async sendAnswer(value: any, student: Student, question: Question, sessionId: string, serieId: string, source: string): Promise<Answer> {
    let answer: Answer;
    let answers: string[] = [];

    if (!!value && !!value.question1) {
      if (!!value.question2) {
        answers = value.question1.concat(value.question2);
      } else {
        answers = value.question1;
      }
    }

    answer = new Answer({
      id: null,
      state: (!!question) ? this.getAnswerState(question, answers) : 'false',
      serieId: (!!serieId) ? serieId : null,
      timespent: 0,
      // DATE TO CHANGE
      updatedAt: this.dateToFormatFr().toISOString(),
      sessionId: (!!sessionId) ? sessionId : null,
      questionId: (!!question && !!question.id) ? question.id : null,
      answer: answers
    });

    if (!!student) {
      return this.postAnswer(answer, student, source);
    }
    return null;
  }

  async postAnswer(answer: Answer, student: Student, source: string): Promise<Answer> {
    return this.post(answer, student, source);
  }

  async saveAnswer(answer: Answer, sessionId: string, mode: string): Promise<Session> {
    const student = await this.studentService.getProfil();
    // console.log('saveAnswer ', JSON.stringify(answer));
    if (!!student && !!sessionId && !!answer && !!answer.serieId) {
      let sessions = [];
      const resGetSessions = await this.sessionsService.sessionsContext.getSessions(student);
      if (!!resGetSessions && !!resGetSessions.errorCode && resGetSessions.errorCode === 'E301') {
        // this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSessions', platform: 'both', data: resgetSessions.errorOriginal, errorCode: 'ssfss'});
      } else {
        sessions = resGetSessions;
      }
      let session = await this.sessionsService.sessionsContext.getSession(sessionId, student);
      // console.log('AnswerService saveAnswer Sessions', JSON.stringify(sessions));
      // console.log('AnswerService saveAnswer Session', JSON.stringify(session));
      const series = await this.seriesService.seriesContext.getSeriesList(student);
      const serie = await this.seriesService.seriesContext.getSerie(answer.serieId, student, true);
      if (!!sessions && !!serie) {
        sessions = this.updateSessions(sessions, answer, serie, sessionId);
        session = this.updateSession(session, answer, serie);
      }
      // console.log('AnswerService saveAnswer Sessions after ', JSON.stringify(sessions));
      // console.log('AnswerService saveAnswer Session after ', JSON.stringify(session));
      // console.log('sessions => ', sessions);
      // console.log('session => ', session);

      // console.log('series => ', series);
      // console.log('serie => ', serie);
      if (!!series && !!serie) {
        const index = (mode === 'examens') ? 1 : 0;
        series.find(elt => elt.id === serie.id).lastSessions[index] = sessionId;
        serie.lastSessions[index] = sessionId;
        this.seriesService.currentSerie = serie;
        await this.seriesService.seriesContext.setSeriesList(series, student);
        await this.seriesService.seriesContext.setSerie(serie, student);
      }
      // console.log('series => ', series);
      // console.log('serie => ', serie);

      await this.sessionsService.sessionsContext.setSession(session, student);
      await this.sessionsService.sessionsContext.setSessions(sessions, student);

      return session;
    }
    return null;
  }

  updateSession(session: Session, answer: Answer, serie: Serie) {
    if (session.type !== 'Error') {
      session.answers.push(answer);
    }
    // DATE TO CHANGE
    session.lastAnsweredAt = this.dateToFormatFr().toISOString();
    // console.log(JSON.stringify(session.answersCount));
    session.answersCount++;
    // console.log(JSON.stringify(session.answersCount));
    if (answer.state === 'right') {
      session.stats.goodAnswers++;
    } else {
      session.stats.badAnswers++;
    }
    if (session.type !== 'Error' && session.answersCount === serie.nbQuestions) {
      if (session.stats.goodAnswers >= MIN_GOOD_ANSWERS || serie.type === 'Discovery') {
        session.state = 'right';
      } else {
        session.state = 'wrong';
      }
    }
    return session;
  }

  updateSessions(sessions: Session[], answer: Answer, serie: Serie, sessionId: string): Session[] {
    const sessionFound = (!!sessionId) ? sessions.find(elt => elt.id === sessionId) : null;
    // console.log('updateSessions sessionFound ', JSON.stringify(sessionFound));
    if (!!sessionFound) {
      if (!!sessionFound.answers && sessionFound.answers.find(elt => elt.questionId === answer.questionId) === undefined) {
        if (!!sessionFound.fakeId && sessionFound.fakeId === null && !!answer && answer.id === 'offline') {
          sessionFound.fakeId = 'update';
        }
        if (!!sessionFound.type && sessionFound.type !== 'Error') {
          sessionFound.answers.push(answer);
        }
        // DATE TO CHANGE
        sessionFound.lastAnsweredAt = this.dateToFormatFr().toISOString();
        // console.log('sessions answersCount before ', sessionFound.answersCount);
        if (!!sessionFound.answersCount) {
          sessionFound.answersCount++;
        } else {
          sessionFound.answersCount = 1;
        }
        // console.log('sessions answersCount after ', sessionFound.answersCount);
        if (!!answer.state && answer.state === 'right') {
          sessionFound.stats.goodAnswers = (!!sessionFound.stats && !!sessionFound.stats.goodAnswers) ? sessionFound.stats.goodAnswers + 1 : 1;
        } else {
          sessionFound.stats.badAnswers = (!!sessionFound.stats && !!sessionFound.stats.badAnswers) ? sessionFound.stats.badAnswers + 1 : 1;
        }
        if (
          !!sessionFound.type && sessionFound.type !== 'Error' &&
          !!sessionFound.answersCount && !!serie.nbQuestions &&
          sessionFound.answersCount === serie.nbQuestions
        ) {
          if (
            (
              !!sessionFound.stats && !!sessionFound.stats &&
              sessionFound.stats.goodAnswers >= MIN_GOOD_ANSWERS
            ) ||
            (!!serie.type && serie.type === 'Discovery')
          ) {
            sessionFound.state = 'right';
          } else {
            sessionFound.state = 'wrong';
          }
        }
      }
      return sessions;
    }
    this.sentryService.sendToSentry(`UpdateSession: Session not found => ${sessionId} ${JSON.stringify(sessions)}`, this.studentService.student);
    return sessions;
  }
}
