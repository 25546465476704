import { EvsRecord } from './evsRecord';

export class CreditStock {
  name: string;
  quantity: number;
  ranking: number;
  redirectOfferId: string;
  subname: string;
  quantityDetails: any;
}

export class CreditStocks {
  [key: string]: CreditStock
}

export interface IStudent {
  remoteId: string;
  status: string;
  subStatus: any[];
  email: string;
  phone: string;
  picUrl: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  postalCode: string;
  code: boolean;
  credits: number;
  codeExamCredits: number;
  drivingExamCredits: number;
  creditStocks: CreditStocks;
  codePassedAt: string;
  dateLastStatusChange: string;
  initialStatus: string;
  initialWish: string;
  engagedAt: string;
  paymentsBalance: number;
  couponsName: any[];
  creditsObtained: number;
  lessonsPassed: any[];
  lessonsToCome: any[];
  offers: any;
  provider: string;
  neph: string;
  lastCgaacUrlDoc: string;
  cgaacAcceptedAt: string;
  learningContractAcceptedAt: string;
  showLearningContract: string;
  evalPostAchat: boolean;
  emailConfirmedAt: string;
  dateLimitToConfirmEmail: string;
  eligibleForRDVP: boolean;
  examReservation: number;
  driving_department: any;
  near_bva_location: any;
  elearningEligibility: boolean;
}



export class Student extends EvsRecord implements IStudent {
  private _remoteId: string;
  private _status: string;
  private _subStatus: any[];
  private _email: string;
  private _phone: string;
  private _picUrl: string;
  private _firstName: string;
  private _lastName: string;
  private _gender: string;
  private _dob: string;
  private _postalCode: string;
  private _code: boolean;
  private _credits: number;
  private _codeExamCredits: number;
  private _drivingExamCredits: number;
  private _creditStocks: CreditStocks;
  private _codePassedAt: string;
  private _dateLastStatusChange: string;
  private _initialStatus: string;
  private _initialWish: string;
  private _engagedAt: string;
  private _paymentsBalance: number;
  private _couponsName: any[];
  private _creditsObtained: number;
  private _lessonsPassed: any[];
  private _lessonsToCome: any[];
  private _offers: any[];
  private _provider: string;
  private _neph: string;
  private _lastCgaacUrlDoc: string;
  private _cgaacAcceptedAt: string;
  private _learningContractAcceptedAt: string;
  private _showLearningContract: string;
  private _evalPostAchat: boolean;
  private _emailConfirmedAt: string;
  private _dateLimitToConfirmEmail: string;
  private _eligibleForRDVP: boolean;
  private _examReservation: number;
  private _driving_department: any;
  private _near_bva_location: any;
  private _elearningEligibility: boolean;

  constructor(obj: IStudent) {
    super(obj);
  }

  isGuest(): boolean {
    return this._status === 'guest';
  }

  // GET
  get remoteId(): string { return this._remoteId; }
  get status(): string { return this._status; }
  get subStatus(): any[] { return this._subStatus; }
  get email(): string { return this._email; }
  get phone(): string { return this._phone; }
  get picUrl(): string { return this._picUrl; }
  get firstName(): string { return this._firstName; }
  get lastName(): string { return this._lastName; }
  get gender(): string { return this._gender; }
  get dob(): string { return this._dob; }
  get postalCode(): string { return this._postalCode; }
  get code(): boolean { return this._code; }
  get credits(): number { return this._credits; }
  get codeExamCredits(): number { return this._codeExamCredits; }
  get drivingExamCredits(): number { return this._drivingExamCredits; }
  get creditStocks(): CreditStocks { return this._creditStocks; }
  get codePassedAt(): string { return this._codePassedAt; }
  get dateLastStatusChange(): string { return this._dateLastStatusChange; }
  get initialStatus(): string { return this._initialStatus; }
  get initialWish(): string { return this._initialWish; }
  get engagedAt(): string { return this._engagedAt; }
  get paymentsBalance(): number { return this._paymentsBalance; }
  get couponsName(): any[] { return this._couponsName; }
  get creditsObtained(): number { return this._creditsObtained; }
  get lessonsPassed(): any[] { return this._lessonsPassed; }
  get lessonsToCome(): any[] { return this._lessonsToCome; }
  get offers(): any[] { return this._offers; }
  get provider(): string { return this._provider; }
  get neph(): string { return this._neph; }
  get lastCgaacUrlDoc(): string { return this._lastCgaacUrlDoc; }
  get cgaacAcceptedAt(): string { return this._cgaacAcceptedAt; }
  get learningContractAcceptedAt(): string { return this._learningContractAcceptedAt; }
  get showLearningContract(): string { return this._showLearningContract; }
  get evalPostAchat(): boolean { return this._evalPostAchat; }
  get emailConfirmedAt(): string { return this._emailConfirmedAt; }
  get dateLimitToConfirmEmail(): string { return this._dateLimitToConfirmEmail; }
  get eligibleForRDVP(): boolean { return this._eligibleForRDVP; }
  get examReservation(): number { return this._examReservation; }
  get driving_department(): any { return this._driving_department; }
  get near_bva_location(): any { return this._near_bva_location; }
  get elearningEligibility(): boolean { return this._elearningEligibility; }
  
  // SET
  set remoteId(id: string) { this._remoteId = id; }
  set status(status: string) { this._status = status; }
  set subStatus(subStatus: any[]) { this._subStatus = subStatus; }
  set email(mail: string) { this._email = mail; }
  set phone(phone: string) { this._phone = phone; }
  set picUrl(url: string) { this._picUrl = url; }
  set firstName(name: string) { this._firstName = name; }
  set lastName(name: string) { this._lastName = name; }
  set gender(gender: string) { this._gender = gender; }
  set dob(date: string) { this._dob = date; }
  set postalCode(postal: string) { this._postalCode = postal; }
  set code(code: boolean) { this._code = code; }
  set credits(credits: number) { this._credits = credits; }
  set codeExamCredits(credits: number) { this._codeExamCredits = credits; }
  set drivingExamCredits(credits: number) { this._drivingExamCredits = credits; }
  set creditStocks(credit_stocks: CreditStocks) { this._creditStocks = credit_stocks; }
  set codePassedAt(date: string) { this._codePassedAt = date; }
  set dateLastStatusChange(date: string) { this._dateLastStatusChange = date; }
  set initialStatus(status: string) { this._initialStatus = status; }
  set initialWish(wish: string) { this._initialWish = wish; }
  set engagedAt(date: string) { this._engagedAt = date; }
  set paymentsBalance(balance: number) { this._paymentsBalance = balance; }
  set couponsName(name: any[]) { this._couponsName = name; }
  set creditsObtained(credits: number) { this._creditsObtained = credits; }
  set lessonsPassed(lessons: any[]) { this._lessonsPassed = lessons; }
  set lessonsToCome(lessons: any[]) { this._lessonsToCome = lessons; }
  set offers(offers: any[]) { this._offers = offers; }
  set provider(provider: string) { this._provider = provider; }
  set neph(neph: string) { this._neph = neph; }
  set lastCgaacUrlDoc(lastCgaacUrlDoc: string) { this._lastCgaacUrlDoc = lastCgaacUrlDoc; }
  set cgaacAcceptedAt(cgaacAcceptedAt: string) { this._cgaacAcceptedAt = cgaacAcceptedAt; }
  set learningContractAcceptedAt(learningContractAcceptedAt: string) { this._learningContractAcceptedAt = learningContractAcceptedAt; }
  set showLearningContract(showLearningContract: string) { this._showLearningContract = showLearningContract; }
  set evalPostAchat(evalPostAchat: boolean) { this._evalPostAchat = evalPostAchat; }
  set emailConfirmedAt(emailConfirmedAt: string) { this._emailConfirmedAt = emailConfirmedAt; }
  set dateLimitToConfirmEmail(dateLimitToConfirmEmail: string) { this._dateLimitToConfirmEmail = dateLimitToConfirmEmail; }
  set eligibleForRDVP(eligibleForRDVP: boolean) { this._eligibleForRDVP = eligibleForRDVP; }
  set examReservation(examReservation: number) { this._examReservation = examReservation; }
  set driving_department(driving_department: any) { this._driving_department = driving_department; }
  set near_bva_location(near_bva_location: any) { this._near_bva_location = near_bva_location; }
  set elearningEligibility(elearningEligibility: boolean) { this._elearningEligibility = elearningEligibility; }
}
